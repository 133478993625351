<template>
  <transition name="el-fade-in">
    <div
      v-if="hasAnyContent"
      style="display: block"
      :style="bubbleStyle(messageIndex)"
      :class="stickyMenuClass"
    >
      <!-- Slider Message -->
      <MessageSlider
        v-if="isSliderMessage"
        :message="message"
        @sendPostback="$emit('sendPostback', $event)"
        :buttonOutline="buttonOutline"
      />
      <!-- Bubble Message -->
      <MessageBubble
        v-else-if="isTextMessage"
        :message="message"
        :is-user-message="isUserMessage"
        :button-outline="buttonOutline"
        :agents="agents"
        :should-disable-if-in-livechat="shouldDisableIfInLivechat"
        @sendPostback="$emit('sendPostback', $event)"
        @handleExpandVideo="$emit('handleExpandVideo', message)"
      />
      <!-- Grid -->
      <MessageGrid
        v-else-if="isGridMessage"
        :message="message"
        :should-disable-if-in-livechat="shouldDisableIfInLivechat"
        @sendPostback="$emit('sendPostback', $event)"
        @setLightBoxImage="$emit('setLightBoxImage', $event)"
      />
      <!--Image message-->
      <!--File message-->
      <MessageFile
        v-else-if="containSingleFileContent"
        :message="message"
        :is-user-message="isUserMessage"
        :button-outline="buttonOutline"
        @sendPostback="$emit('sendPostback', $event)"
        @setLightBoxImage="$emit('setLightBoxImage', $event)"
      />

      <!-- Image message from bot -->
      <!-- Carousel -->
      <MessageCarousel
        v-else-if="isCarouselMessage"
        :message="message"
        :button-outline="buttonOutline"
        :should-disable-if-in-livechat="shouldDisableIfInLivechat"
        :backgroundStyle="backgroundStyle"
        :titleStyle="titleStyle"
        :iconStyle="iconStyle"
        @sendPostback="$emit('sendPostback', $event)"
        @setLightBoxImage="$emit('setLightBoxImage', $event)"
      />

      <!-- Compact -->
      <MessageCompact
        v-else
        :message="message"
        :button-outline="buttonOutline"
        :should-disable-if-in-livechat="shouldDisableIfInLivechat"
        :backgroundStyle="backgroundStyle"
        :titleStyle="titleStyle"
        @sendPostback="$emit('sendPostback', $event)"
        @setLightBoxImage="$emit('setLightBoxImage', $event)"
      />

      <!--Quick Replies-->
      <MessageQuickReplies
        v-if="shouldShowQuickReplies"
        :message="message"
        :should-disable-if-in-livechat="shouldDisableIfInLivechat"
        :button-outline="buttonOutline"
        @sendPostback="$emit('sendPostback', $event)"
      />
      <MessageSingPass v-if="showSingPassQR" :button-outline="buttonOutline" />
    </div>
  </transition>
</template>

<script>
import _ from "lodash-es";
import "viewerjs/dist/viewer.css";

import { MessageBox } from "element-ui";
import MessageBubble from "./Bubble";
import MessageFile from "./File";
import MessageQuickReplies from "./QuickReplies";
import MessageCarousel from "./Carousel";
import MessageCompact from "./Compact";
import MessageGrid from "./Grid";
import MessageSlider from "./Slider";
import { mapGetters } from "vuex";
import MessageSingPass from "./Singpass";

export default {
  name: "Message",
  components: {
    MessageFile,
    MessageBubble,
    MessageQuickReplies,
    MessageCarousel,
    MessageCompact,
    MessageGrid,
    MessageSlider,
    MessageSingPass,
  },
  data() {
    return {
      isStickyMenu: false,
      authSession: null,
    };
  },
  props: {
    message: {
      type: Object,
      default: function () {
        return {};
      },
    },
    showQuickReply: {
      type: Boolean,
      default: false,
    },
    agents: {
      default: Array,
    },
    backgroundStyle: {
      type: Object,
      default: function () {
        return {};
      },
    },
    titleStyle: {
      type: Object,
      default: function () {
        return {};
      },
    },
    iconStyle: {
      type: Object,
      default: function () {
        return {};
      },
    },
    maintainBannerHeight: {
      type: Number,
      default: 0,
    },
    messageIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(["isUiBetaEnabled", "stickyMenu"]),
    /**
     * @description Is message text only message
     * @return {boolean}
     */
    isTextMessage() {
      const singleContent = this.message.data.content && this.message.data.content.length === 1;
      const images = _.get(this.message, "data.content[0].images");
      const files = _.get(this.message, "data.content[0].files");
      const videos = _.get(this.message, "data.content[0].videos");
      // validating images, files, and videos item not null
      const isImageMessage =
        _.get(this.message, "data.content[0].image") ||
        (Array.isArray(images) && _.compact(images).length > 0);
      const isFileMessage = Array.isArray(files) && _.compact(files).length > 0;
      const isVideoMessage = Array.isArray(videos) && _.compact(videos).length > 0;
      const isListStyle = _.get(this.message, "data.listStyle") === "compact";

      return singleContent && !(isImageMessage || isFileMessage || isVideoMessage || isListStyle);
    },
    /**
     * @description Is message an carousel render
     * @return {boolean}
     */
    isCarouselMessage() {
      return this.message.data.listStyle !== "compact";
    },
    /**
     * @description Is message an grid render
     * @return {boolean}
     */
    isGridMessage() {
      return this.message.options?.mode === "grid" && this.isUiBetaEnabled;
    },

    /** @description Is message an slider render
     * @return {boolean
     */
    isSliderMessage() {
      return this.message.options?.mode === "slider" && this.isUiBetaEnabled;
    },
    /**
     * @description to check if single file (non-image) content
     * @return {boolean}
     */
    containSingleFileContent() {
      return (
        this.message.data.content &&
        this.message.data.content.length === 1 &&
        (this.message.data.content[0].images ||
          this.message.data.content[0].image ||
          this.message.data.content[0].files ||
          this.message.data.content[0].videos) &&
        this.message.data.listStyle !== "compact" &&
        !_.has(this.message.data.content[0], "text") &&
        !_.has(this.message.data.content[0], "subtext")
      );
    },
    shouldDisableIfInLivechat() {
      return this.settings.disablePostbackDuringLivechat && this.settings.liveChatStatus;
    },

    /**
     * @description Is message not empty
     * @return {boolean}
     */
    hasAnyContent() {
      return !_.isEmpty(this.message.data.content);
    },

    /**
     * @description Most of the webchat config
     * @return {any}
     */
    settings() {
      return this.$store.getters.settings;
    },

    /**
     * @description Should show quick replies
     * @return {boolean}
     */
    shouldShowQuickReplies() {
      return this.showQuickReply && !_.isEmpty(this.message.data.quickReplies);
    },

    /**
     * @description Message type
     * @description Determine left or right bubble
     * @return {boolean}
     */
    isUserMessage() {
      return this.message.type === "message" || this.message.type === "postback";
    },

    /**
     * @description Element UI button ouline
     * @return {string}
     */
    buttonOutline() {
      switch (this.settings.buttonOutlineColor) {
        case "red":
          return "danger";
        case "blue":
          return "primary";
        case "green":
          return "success";
        default:
          return "primary";
      }
    },
    stickyMenuClass() {
      if (this.isStickyMenu) return `sticky-menu-${this.stickyMenu[this.message.options.nodeId]}`;
      return null;
    },
    showSingPassQR() {
      const isQr = this.message?.data?.content[0]?.options?.showQRCode;
      const hideQr = this.message?.data?.content[0]?.options?.disabledQR;
      const el = document.getElementById("ndi-qr");
      if (!el && isQr) return true;
      if (el && isQr) {
        this.$nextTick(() => {
          el.scrollIntoView({ behavior: "smooth" });
        });
      }
      if (el && hideQr) {
        this.$nextTick(() => {
          el.remove();
        });
      }
      return false;
    },
  },
  methods: {
    bubbleStyle(messageIndex) {
      let margin = "0px 0px 0px 0px";
      if (this.maintainBannerHeight > 0 && messageIndex === 0) {
        margin = `${this.maintainBannerHeight}px 0px 0px 0px`;
      }
      return {
        textAlign: this.isUserMessage ? "right" : "left",
        margin,
      };
    },
  },
  mounted() {
    const images = document.querySelectorAll(".kr-preview-image");
    if (images.length > 0) {
      images.forEach((image) => {
        image.addEventListener("click", () => {
          this.$emit("setLightBoxImage", image.src);
        });
      });
    }
  },
  beforeDestroy() {
    if (this.authSession === "SUCCESSFUL") {
      window.NDI.cancelAuthSession();
    }
  },
  created() {
    if (!this.isUserMessage && _.has(this.stickyMenu, this.message.options?.nodeId)) {
      this.isStickyMenu = true;
      this.$store.commit("SET_STICKY_MENU_LANGUAGE", this.stickyMenu[this.message.options.nodeId]);
    }
  },
};
</script>

<style>
.kr-preview-image {
  cursor: pointer;
}
</style>
