<template>
  <div>
    <el-container
      id="keyreply-chat-window"
      v-loading="loading || uploading"
      :class="{
        tall: tall,
        wide: settings.window === 'wide',
        full: settings.window === 'full',
        iossdk: iOSSDK,
        [`right-${chatWindowRight}`]: isRtlModeEnabled,
      }"
      :style="{
        bottom: chatWindowBottom,
        height: chatWindowHeight,
        right: !isRtlModeEnabled && chatWindowRight + 'px',
        top: !tall && chatWindowTop + 'px',
        left: !tall && chatWindowLeft + 'px',
      }"
      :element-loading-text="loadingText"
      ref="keyReplyChatWindow"
    >
      <!--  Keyreply Menu Header Modal  -->
      <transition name="fade">
        <div
          id="keyreply-header-menu"
          v-if="toShowHeaderMenu && isUiBetaEnabled"
          @click.self="closeHeaderMenu"
        >
          <div class="keyreply-header-menu-container">
            <div
              class="keyreply-header-menu-section"
              :style="backgroundStyle"
              v-if="isShowWidgetSizeSection"
            >
              <span class="keyreply-header-menu-section-tittle" :style="titleStyle"
                >Widget Size</span
              >
              <div class="keyreply-header-menu-button-group">
                <template v-if="!$device.Mobile && !tallViewSettings">
                  <div
                    v-if="!tall"
                    class="keyreply-header-button-item"
                    @click="handleChangeWidgetSize(true)"
                  >
                    <div class="keyreply-header-button" :style="iconStyle">
                      <i class="el-icon-full-screen" />
                    </div>
                    <span>Expand</span>
                  </div>
                  <div
                    v-else
                    class="keyreply-header-button-item"
                    @click="handleChangeWidgetSize(false)"
                  >
                    <div class="keyreply-header-button" :style="iconStyle">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 3V4C8 5.88562 8 6.82843 7.41421 7.41421C6.82843 8 5.88562 8 4 8H3"
                          :stroke="$store.getters.settings.widgetIconTextColor"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16 3V4C16 5.88562 16 6.82843 16.5858 7.41421C17.1716 8 18.1144 8 20 8H21"
                          :stroke="$store.getters.settings.widgetIconTextColor"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8 21V20C8 18.1144 8 17.1716 7.41421 16.5858C6.82843 16 5.88562 16 4 16H3"
                          :stroke="$store.getters.settings.widgetIconTextColor"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16 21V20C16 18.1144 16 17.1716 16.5858 16.5858C17.1716 16 18.1144 16 20 16H21"
                          :stroke="$store.getters.settings.widgetIconTextColor"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <span>Default</span>
                  </div>
                </template>

                <!-- <div class="keyreply-header-button-item" @click="closeWindow" v-if="!isMobileMode">
                  <div class="keyreply-header-button" :style="iconStyle">
                    <i class="el-icon-arrow-down" />
                  </div>
                  <span>Minimize</span>
                </div> -->
              </div>
            </div>
            <div class="keyreply-header-menu-section" :style="backgroundStyle">
              <span class="keyreply-header-menu-section-tittle" :style="titleStyle"
                >Chat History</span
              >
              <div class="keyreply-header-menu-button-group">
                <div class="keyreply-header-button-item" @click="clearCache">
                  <div class="keyreply-header-button" :style="iconStyle">
                    <i class="el-icon-delete" />
                  </div>
                  <span>Clear</span>
                </div>
                <div
                  class="keyreply-header-button-item"
                  @click="downloadHistory"
                  v-if="settings.showHistoryDownload"
                >
                  <div class="keyreply-header-button" :style="iconStyle">
                    <i class="el-icon-download"></i>
                  </div>
                  <span>Download</span>
                </div>
              </div>
            </div>
            <!-- <div class="keyreply-header-menu-section" :style="backgroundStyle">
              <span class="keyreply-header-menu-section-tittle" :style="titleStyle"
                >Appearance</span
              >
              <div class="keyreply-header-menu-button-group">
                <div class="keyreply-header-button-item">
                  <div class="keyreply-header-button" :style="iconStyle">
                    <i class="el-icon-moon" />
                  </div>
                  <span>Dark Mode</span>
                </div>
                <div class="keyreply-header-button-item">
                  <div class="keyreply-header-button" :style="iconStyle">
                    <svg
                      viewBox="0 0 76 76"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      baseProfile="full"
                      enable-background="new 0 0 76.00 76.00"
                      xml:space="preserve"
                    >
                      <path
                        :fill="iconStyle.color"
                        fill-opacity="1"
                        stroke-width="0.2"
                        stroke-linejoin="round"
                        d="M 33.6585,44.381L 24.397,44.381L 22.0936,52.9919L 14.5596,52.9919L 24.397,21L 33.9944,21L 42.31,47.4041L 47.7438,29.9832L 54.7237,29.9832L 62.0513,53L 56.2942,53L 54.4794,46.7375L 47.7438,46.7375L 46.0686,53L 36.1538,52.9919L 33.6585,44.381 Z M 25.4527,39.1786L 32.6028,39.1786L 30.5873,32.3811L 29.7355,29.2915L 28.9557,26.2024L 28.8598,26.2024L 28.134,29.3193L 27.3722,32.4615L 25.4527,39.1786 Z M 48.5116,42.954L 53.7116,42.954L 52.2458,38.0104L 51.6264,35.7634L 51.0592,33.5168L 50.9894,33.5168L 50.4616,35.7836L 49.9075,38.0688L 48.5116,42.954 Z M 45,21L 57,21L 51,27L 45,21 Z "
                      />
                    </svg>
                  </div>
                  <span>Text Size</span>
                </div>
              </div>
            </div> -->
            <div class="keyreply-header-powered">
              <span>Powered by <span style="color: #a6b5e6">KeyReply</span></span>
            </div>
          </div>
        </div>
      </transition>
      <!-- End Kereply Header Modal -->
      <el-header
        id="keyreply-panel-header"
        :style="headerStyle"
        @mousedown.native="
          $emit('handleGrabElement', { event: $event, elementId: 'keyreply-chat-window' })
        "
        ref="keyreply-panel-header"
      >
        <template v-if="!isMobileModeDefault">
          <span v-if="settings.logo">
            <img
              :src="settings.logo"
              :class="settings.largeLogo ? 'keyreply-chat-logo-large' : 'keyreply-chat-logo'"
            />
          </span>
          <el-tabs
            v-if="settings.tabs && Array.isArray(settings.tabs) && settings.tabs.length > 0"
            ref="tabs"
            :stretch="true"
            :value="settings.tabs[0].url"
            v-bind:style="{
              display: 'inline-block',
              width: settings.window === 'wide' ? '80%' : '70%',
            }"
            @tab-click="handleTabClick"
          >
            <el-tab-pane v-for="(tab, index) in settings.tabs" :key="index" :name="tab.url">
              <span slot="label" :style="tab.style">{{ tab.title }}</span>
            </el-tab-pane>
          </el-tabs>

          <div
            v-else
            :class="settings.largeLogo ? 'keyreply-chat-title-large' : 'keyreply-chat-title'"
          >
            {{ settings.title }}
          </div>

          <div class="keyreply-header-button" v-if="!isUiBetaEnabled">
            <el-button
              v-if="!sessionEnded && sessionButtonReady"
              style="margin-right: 12px"
              round
              plain
              type="success"
              size="mini"
              :loading="endButtonLoading"
              @click="endSession"
              >{{ settings.liveChatStatus ? "End Live Chat" : "End Chat" }}</el-button
            >
            <el-button
              v-else-if="sessionButtonReady"
              style="margin-right: 12px"
              round
              plain
              type="primary"
              size="mini"
              :loading="endButtonLoading"
              @click="startSession"
              >Start New Chat</el-button
            >
            <div
              v-if="!$device.Mobile && !tallViewSettings"
              style="margin-right: 12px"
              :class="tall ? 'el-icon-bottom-left' : 'el-icon-full-screen'"
              @click="tall = !tall"
            />

            <el-dropdown
              :style="isMobileMode ? 'margin-right: 36px' : 'margin-right: 12px'"
              size="small"
              placement="bottom-end"
              :trigger="triggerOption"
              @command="handleCommand"
            >
              <span :style="{ color: settings.headerFontColor || 'white' }">
                <div class="el-icon-more" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="endChat"
                  v-if="postChatFormEnabled && !endSessionButtonEnabled"
                  >End Chat</el-dropdown-item
                >
                <el-dropdown-item command="clearCache">{{
                  `Clear ${endSessionButtonEnabled ? "Cache" : "History"}`
                }}</el-dropdown-item>
                <el-dropdown-item v-if="showHistoryDownload" command="downloadHistory"
                  >Download History</el-dropdown-item
                >
                <el-dropdown-item v-if="showClearCloneState" command="clearCloneState"
                  >Clear Clone State</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>

            <el-tooltip
              v-if="!isMobileMode"
              v-touch:tap="tapHandler"
              :hide-after="1000"
              content="Minimize"
              placement="bottom"
            >
              <div class="el-icon-arrow-down" @click="closeWindow" />
            </el-tooltip>
          </div>
          <div class="keyreply-header-button" v-else>
            <!-- New header button -->
            <div style="display: flex">
              <el-button
                v-if="!sessionEnded && sessionButtonReady"
                style="margin-right: 12px"
                round
                plain
                type="success"
                size="mini"
                :loading="endButtonLoading"
                @click="endSession"
                >{{ settings.liveChatStatus ? "End Live Chat" : "End Chat" }}</el-button
              >
              <el-button
                v-else-if="sessionButtonReady"
                style="margin-right: 12px"
                round
                plain
                type="primary"
                size="mini"
                :loading="endButtonLoading"
                @click="startSession"
                >Start New Chat</el-button
              >

              <div
                style="
                  display: flex;
                  align-items: center;
                  margin: 0 10px;
                  font-size: 24px;
                  font-weight: bolder;
                "
                @click="closeWindow"
              >
                <el-tooltip :hide-after="1000" content="Minimize widget" placement="bottom">
                  <div
                    style="width: 20px; height: 3px; border-radius: 5px"
                    :style="{
                      backgroundColor: headerModalIconColor || '#4e6cce',
                    }"
                  ></div>
                </el-tooltip>
              </div>

              <el-tooltip
                @click.native="openHeaderMenu"
                :hide-after="1000"
                content="Menu"
                placement="bottom"
              >
                <svg
                  width="32"
                  height="100%"
                  viewBox="0 0 25 25"
                  xmlns="http://www.w3.org/2000/svg"
                  :fill="headerModalIconColor"
                >
                  <path
                    d="M4 4h4v4H4zm6 0h4v4h-4zm6 0h4v4h-4zM4 10h4v4H4zm6 0h4v4h-4zm6 0h4v4h-4zM4 16h4v4H4zm6 0h4v4h-4zm6 0h4v4h-4z"
                  />
                </svg>
              </el-tooltip>
            </div>
          </div>
        </template>
        <el-collapse-transition>
          <div v-if="!isConnected">
            <!-- <div v-if="$store.state.socketConnectionStatus"> -->
            <div
              class="keyreply-disconnected-banner"
              :style="bannerStyle"
              :class="{
                'transition-box': isUiBetaEnabled,
              }"
            >
              <span>
                <i class="el-icon-warning-outline" />
                {{ isUiBetaEnabled ? "Please check your internet connection…" : "Disconnected." }}
              </span>
            </div>
          </div>
          <!-- <div v-else-if="settings.typingIndicator.status && isUiBetaEnabled">
            <div class="keyreply-typing-banner transition-box" :style="bannerStyle">
              <span>{{ settings.name }} is typing...</span>
            </div>
          </div> -->
          <div v-else-if="settings.liveChatStatus">
            <div
              v-if="settings.largeLogo"
              class="keyreply-livechat-banner-large"
              :style="bannerStyle"
            >
              <span> <i class="el-icon-service" /> Live Chat in progress </span>
              <el-button
                v-if="!endSessionButtonEnabled"
                class="keyreply-livechat-end-button"
                type="primary"
                size="small"
                round
                plain
                @click.native="endLiveChat()"
                >End Live Chat</el-button
              >
            </div>
            <div v-else class="keyreply-livechat-banner" :style="bannerStyle">
              <span> <i class="el-icon-service" /> Live Chat in progress </span>
              <el-button
                v-if="!endSessionButtonEnabled"
                class="keyreply-livechat-end-button"
                type="primary"
                size="small"
                round
                plain
                @click.native="endLiveChat()"
                >End Live Chat</el-button
              >
            </div>
          </div>
          <div
            v-else-if="isMaintenanceBannerEnabled && isShowMaintenanceBanner"
            :style="bannerStyle"
          >
            <div class="keyreply-maintenance-banner">
              <span
                id="keyreply-maintenance-banner-text"
                v-html="$options.filters.markHTML(settings.maintenanceBanner.message)"
              ></span>
              <!-- close icon -->
              <el-button
                class="keyreply-maintenance-banner-close"
                type="text"
                @click="closeMaintenanceBanner"
              >
                <i class="el-icon-close" />
              </el-button>
            </div>
          </div>
        </el-collapse-transition>
        <el-collapse-transition>
          <div v-if="showingNotifBanner" @click="requestNotificationPermission">
            <div class="keyreply-notification-banner">
              <span id="keyreply-notification-banner-text"
                >Enable Notification Permission in your browser</span
              >
            </div>
          </div>
        </el-collapse-transition>
        <div :style="floatingButtonStyle" v-if="isMobileModeDefault">
          <el-button
            v-if="sessionButtonReady && settings.liveChatStatus"
            style="margin-right: 12px"
            round
            plain
            type="success"
            size="mini"
            :loading="endButtonLoading"
            @click="endSession"
          >
            End Live Chat</el-button
          >
          <el-button
            v-if="sessionEnded && sessionButtonReady"
            style="margin-right: 12px"
            round
            plain
            type="primary"
            size="mini"
            :loading="endButtonLoading"
            @click="startSession"
          >
            Start New Chat
          </el-button>
          <el-dropdown
            v-if="!isUiBetaEnabled"
            style="margin-right: 12px"
            size="small"
            placement="bottom-end"
            :trigger="triggerOption"
            @command="handleCommand"
          >
            <el-button
              :type="buttonOutline"
              icon="el-icon-more"
              :loading="endButtonLoading"
              plain
              circle
            ></el-button>
            <el-dropdown-menu v-if="!endButtonLoading" slot="dropdown">
              <el-dropdown-item
                command="endChat"
                v-if="postChatFormEnabled && !endSessionButtonEnabled"
                >End Chat</el-dropdown-item
              >
              <el-dropdown-item
                v-if="!sessionEnded && sessionButtonReady && !settings.liveChatStatus"
                command="endSession"
                >End Chat Session</el-dropdown-item
              >
              <el-dropdown-item command="clearCache">
                {{ clearButtonText }}
              </el-dropdown-item>
              <el-dropdown-item v-if="showHistoryDownload" command="downloadHistory">
                Download History
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            v-else
            class="floating-mobile-button"
            style="margin-right: 12px"
            @click.native="openHeaderMenu"
            :type="buttonOutline"
            :loading="endButtonLoading"
            circle
            plain
          >
            <svg
              class="floating-mobile-menu-icon"
              width="24"
              height="100%"
              viewBox="0 0 25 25"
              xmlns="http://www.w3.org/2000/svg"
              :fill="headerModalIconColorMobile"
            >
              <path
                d="M4 4h4v4H4zm6 0h4v4h-4zm6 0h4v4h-4zM4 10h4v4H4zm6 0h4v4h-4zm6 0h4v4h-4zM4 16h4v4H4zm6 0h4v4h-4zm6 0h4v4h-4z"
              />
            </svg>
          </el-button>
        </div>
        <div v-show="!(lastVideo || selectedVideo) && !isMinimizeVideo">
          <TrendingTopics
            @sendPostback="sendPostback"
            :isOpenList="isOpenTrendingTopic"
            @handleTrendingList="handleTrendingTopicList"
          />
        </div>
      </el-header>
      <transition-group
        name="el-zoom-in-top"
        v-if="(lastVideo || selectedVideo) && !isMinimizeVideo"
      >
        <el-main id="keyreply-panel-body" class="keyreply-video" key="main">
          <Video
            :videoBotMessage="selectedVideo ? selectedVideo : lastVideo"
            :agents="agents"
            :chatbox="chatbox"
            @sendPostback="sendPostback"
            @setLightBoxImage="lightBoxImage = $event"
            @sendMessage="sendMessage"
            @handleTypingMessage="handleTypingMessage"
            @handleMinimizeVideo="handleMinimizeVideo"
            @resetSelectedVideo="resetSelectedVideo"
          />
        </el-main>
      </transition-group>
      <transition-group name="el-zoom-in-top" v-else>
        <el-main
          v-show="webviewUrl && showWebview"
          id="keyreply-panel-frame"
          key="iframe"
          style="overflow: hidden"
          :style="{
            ...mainPositionStyle,
          }"
        >
          <el-button
            circle
            v-if="returnToChatButton"
            size="medium"
            icon="el-icon-back"
            type="info"
            style="position: absolute; bottom: 8%; left: 5%"
            @click="closeWebView"
          ></el-button>
          <!-- <WebviewDownloader :webview-url="iframeWebviewUrl" /> -->
          <iframe
            :src="iframeWebviewUrl"
            ref="iframe"
            style="height: 100%; width: 100%"
            frameborder="0"
            allowfullscreen="true"
            allowtransparency
          >
            <p>Loading</p>
          </iframe>
        </el-main>
        <el-main v-if="toShowPreChatForm" key="main" id="keyreply-panel-body-form-pre" ref="window">
          <PreChatForm
            :settings="settings"
            @setShowPreChatForm="setShowPreChatForm"
            @onPreChatFormSuccess="onPreChatFormSuccess"
          ></PreChatForm>
        </el-main>
        <el-main
          v-else-if="toShowPostChatForm"
          key="main1"
          id="keyreply-panel-body-form-post"
          ref="window"
        >
          <PostChatForm
            :settings="settings"
            @setShowPostChatForm="setShowPostChatForm"
            @clearCache="clearCache"
          ></PostChatForm>
        </el-main>
        <el-main
          v-else
          v-show="!webviewUrl || !showWebview"
          id="keyreply-panel-body"
          key="main2"
          ref="window"
          :style="{
            ...mainPositionStyle,
            backgroundImage: `url(${settings.background})`,
          }"
          @scroll.native="onWidgetScroll"
        >
          <div
            v-if="settings.subtext.text"
            class="keyreply-subtle-link"
            :style="{ marginTop: trendingTopics.length > 0 && `35px` }"
          >
            <a
              :href="settings.subtext.url"
              style="color: rgba(0, 0, 0, 0.4); text-decoration: none"
              target="_blank"
              >{{ settings.subtext.text }}</a
            >
          </div>
          <div v-else-if="showMoreMessagesButton" style="display: flex; justify-content: center">
            <el-button
              type="text"
              :loading="showingMoreMessages"
              class="keyreply-subtle-link"
              style="margin-bottom: 10px; padding: 0px"
              :style="{ marginTop: trendingTopics.length > 0 && `35px` }"
              @click="fetchHistory"
            >
              - show more message -
            </el-button>
          </div>

          <div
            class="keyreply-subtle-link"
            style="margin-bottom: 10px"
            :style="{ marginTop: trendingTopics.length > 0 && `35px` }"
            v-else-if="showLoadAllMessagesButton"
          >
            - loaded all messages -
          </div>
          <div
            :class="!isMobileMode ? 'keyreply-powered' : 'keyreply-powered mobile-mode'"
            v-else-if="!showLoadAllMessagesButton && isUiBetaEnabled"
          >
            <span>Powered By <span style="color: #a6b5e6">KeyReply</span> </span>
          </div>
          <br />

          <message
            v-for="(message, index) in sortedMessages"
            :showQuickReply="showQuickReply(message, index)"
            :key="`message-${index}`"
            :message="message"
            :agents="agents"
            @sendPostback="sendPostback"
            @setLightBoxImage="lightBoxImage = $event"
            :backgroundStyle="backgroundStyle"
            :titleStyle="titleStyle"
            :iconStyle="iconStyle"
            :maintainBannerHeight="maintainBannerHeight"
            :messageIndex="index"
            @handleExpandVideo="handleExpandVideo"
          />

          <el-row>
            <el-col style="margin: 0px 25px 0px 25px">
              <div :style="settings.typingIndicator.status ? 'opacity:1' : 'opacity:0'">
                <img
                  :src="typingIndicatorUrl"
                  style="width: 30px"
                  v-if="!settings.liveChatStatus || !isUiBetaEnabled || !liveChatAgentName"
                />
                <div
                  v-else
                  style="
                    font-size: 13px;
                    margin: 10px -15px;
                    display: flex;
                    align-items: center;
                    font-style: italic;
                    color: #999999;
                  "
                >
                  <span>{{ liveChatAgentName }} is typing</span>
                  <div class="typing">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-main>
      </transition-group>
      <el-footer
        v-if="showTextInput && showTextInputVideo"
        id="keyreply-panel-footer"
        height="auto"
      >
        <div
          v-if="
            suggestions.length > 0 &&
            showSuggestion &&
            chatboxHasValidLength &&
            !settings.liveChatStatus
          "
          class="keyreply-autocomplete-popper"
          @mouseleave="setSuggestIndex(-1)"
        >
          <div
            v-for="(suggestion, index) in suggestions"
            :key="index"
            :class="{ 'keyreply-suggestion-selected': suggestSelect === index }"
            :style="disablePointerStyle"
            class="keyreply-suggestion"
            @click="send"
            @mouseover="setSuggestIndex(index)"
          >
            {{ suggestion.item.value }}
          </div>
        </div>

        <el-dropdown
          v-if="showSideOptions"
          placement="top-start"
          :trigger="triggerOption"
          @command="footerButtonClick"
          style="flex: 0 0 36px"
        >
          <div class="keyreply-footer-button" v-if="!isUiBetaEnabled">
            <i class="el-icon-circle-plus" />
          </div>

          <div class="keyreply-footer-button" :style="iconStyle" v-else>
            <i class="el-icon-arrow-up" />
          </div>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              command="upload photo"
              icon="el-icon-picture-outline"
              v-if="isFileUploadEnable"
              >Upload photo</el-dropdown-item
            >
            <el-dropdown-item
              command="upload document"
              icon="el-icon-document"
              v-if="isFileUploadEnable"
              >Upload document</el-dropdown-item
            >
            <el-dropdown-item v-if="settings.ocrButton" icon="el-icon-picture-outline" command="ocr"
              >OCR</el-dropdown-item
            >
            <el-dropdown-item
              v-if="settings.shareLocation"
              icon="el-icon-location-outline"
              command="share location"
              >Share location</el-dropdown-item
            >
            <el-dropdown-item
              v-if="settings.enableVoice"
              icon="el-icon-microphone"
              command="speech to text"
              >Toggle Voice</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>

        <div style="width: 100%; padding: 7px" v-if="showPushToTalk">
          <el-button
            :type="listening ? 'danger' : 'success'"
            @mousedown.native="startListen"
            @touchstart.native="startListen"
            @mouseup.native="stopListen"
            @touchend.native="startListen"
            style="width: 100%"
            size="small"
            round
          >
            <span v-if="!listening">Push button to speak</span>
            <span v-else>
              <i class="el-icon-loading" />
              {{ this.chatbox || "Release to send" }}
            </span>
          </el-button>
        </div>

        <el-input
          v-else
          :disabled="
            disableTextInput ||
            toShowPreChatForm ||
            toShowPostChatForm ||
            sessionEnded ||
            botIsGenerateAnswer ||
            isLastMessageEndingChat
          "
          :autofocus="true"
          :placeholder="disableTextInput ? chooseOptionLabel : typingsLabel"
          v-model="chatbox"
          ref="textbox"
          type="textarea"
          spellcheck="true"
          class="keyreply-message-input"
          @keydown.enter.native.exact="send"
          @focus="focusInput()"
          @blur="toggleSuggestion(false, 300)"
          @keypress="setSuggestIndex(-1)"
          @input="resizeTextArea"
          :maxlength="settings.charLimit.limit"
          :style="{
            borderRadius: chatboxBorderStyle,
          }"
        />

        <!-- input -->
        <div
          class="keyreply-footer-button keyreply-send-button"
          :class="{
            visible:
              showSendButton && !showPushToTalk && !disableTextInput && !isLastMessageEndingChat,
            disabled:
              !isInternetConnectionAvailable ||
              !this.filteredXSSMessage ||
              disableTextInput ||
              isLastMessageEndingChat,
          }"
          :style="settings.charLimit.showCountDown && 'padding-bottom: 0px; padding-top: 2px;'"
          @click="send"
        >
          <i
            class="el-icon-s-promotion"
            :style="settings.charLimit.showCountDown && 'margin-bottom: -10px;'"
          />
          <span v-show="settings.charLimit.showCountDown" style="font-size: 10px; color: #989898">
            {{ countdownLimitLabel }}
          </span>
        </div>

        <FileUploader ref="uploader" />
      </el-footer>
      <transition name="bounce">
        <div id="keyreply-scroll-button" v-if="isShowScrollButton && !isUserTyping">
          <el-button
            class="circle-button"
            :type="buttonOutline"
            size="mini"
            icon="el-icon-top"
            plain
            circle
          ></el-button>
          <el-button
            :type="buttonOutline"
            size="small"
            class="info-button"
            @click="scrollToStickyMenu"
          >
            <i class="el-icon-arrow-up" style="margin-bottom: 3px"></i>
            <p>{{ stickyMenuText }}</p>
          </el-button>
        </div>
      </transition>
      <div class="btn-video-expand" @click="handleMinimizeVideo(false)" v-if="isMinimizeVideo">
        <i class="el-icon-video-play"></i>
      </div>
    </el-container>
    <web-cam-dialog
      :dialog-visible="showWebCamDialog"
      :data="webCamData"
      @close="showWebCamDialog = false"
    />
    <el-dialog
      class="keyreply-email-dialog"
      title="Forward chat history"
      :visible.sync="emailFormVisible"
      :modal="false"
    >
      <el-form ref="emailForm" :model="form" :rules="emailForm">
        <el-form-item label="Email" prop="userEmail">
          <el-input
            ref="userEmail"
            v-model="form.userEmail"
            autocomplete="off"
            clearable
            @keydown.enter.native="emailFormSubmit"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="emailFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="emailFormSubmit">Confirm</el-button>
      </span>
    </el-dialog>

    <div v-if="lightBoxImage" class="lightbox">
      <div class="full-lightbox" @click="lightBoxImage = null" />
      <a class="lightbox-close-button" @click.prevent="lightBoxImage = null">&times;</a>
      <img class="image-lightbox" :src="lightBoxImage" />
    </div>

    <v-style v-if="$device.ie">
      .message-bubble-left:before { color:
      {{ settings.agentBubbleColor }}!important; border-left: 20px solid transparent!important;
      bottom:2px!important; } .message-bubble-left:after { background:transparent !important; }
      .message-bubble-right:before { color: {{ settings.userBubbleColor }}!important; border-right:
      20px solid transparent!important; bottom:2px!important; }
    </v-style>
    <audio
      id="keyreply-notification-sound"
      hidden
      muted
      :src="notificationSoundUrl"
      type="audio/ogg"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Message from "./Message/Index";
import Video from "./Video/Index";
import { v4 as uuid } from "uuid";
import _ from "lodash-es";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";
import saveAs from "file-saver";
import WebCamDialog from "@/components/WebCamDialog";
import PreChatForm from "@/components/PreChatForm";
import PostChatForm from "@/components/PostChatForm";
import Vue from "vue";
import FileUploader from "./FileUploader/Index";
import Push from "push.js";
import { filterXSS } from "xss";
import getParamsFromUrl from "@/helpers/getParamsFromUrl";
import { parseLanguageBasedLabel } from "@/helpers/langLabels";
import TrendingTopics from "./TrendingTopics/Index.vue";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import signalRHubConnection from "@/helpers/signalRHubConnection";

dayjs.extend(advancedFormat);

Vue.component("v-style", {
  render: function (createElement) {
    return createElement("style", this.$slots.default);
  },
});

const getters = mapGetters({
  messages: "messages",
  open: "chatWindow",
  settings: "settings",
  webviewUrl: "webviewUrl",
  returnToChatButton: "returnToChatButton",
  isTextInputDisabled: "disableTextInput",
  showWebview: "showWebview",
  stickyMenu: "stickyMenu",
  stickyMenuText: "stickyMenuText",
  isUiBetaEnabled: "isUiBetaEnabled",
  isRtlModeEnabled: "isRtlModeEnabled",
  stickyMenuLanguage: "stickyMenuLanguage",
  liveChatAgentName: "liveChatAgentName",
  trendingTopics: "trendingTopics",
});

export default {
  name: "ChatWindow",
  components: {
    Message,
    Video,
    WebCamDialog,
    PreChatForm,
    FileUploader,
    PostChatForm,
    TrendingTopics,
  },
  props: ["isWidgetReadyToDrag", "widgetPos"],
  data() {
    const validateEmail = (rule, value, callback) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const validEmail = re.test(String(value).toLowerCase());
      const emailDomainWhitelist = this.$store.state.emailDomainWhitelist || [];

      if (value === "") {
        callback(new Error("Please input the email address."));
        return;
      } else if (!validEmail) {
        callback(new Error("Please input valid email address."));
        return;
      } else if (!_.isEmpty(emailDomainWhitelist)) {
        const userDomainIsIncludedInWhitelist = _.find(emailDomainWhitelist, (domain) =>
          value.includes(domain)
        );

        if (!userDomainIsIncludedInWhitelist) {
          callback(new Error("Receiver email is not in whitelist. Please contact admin."));
          return;
        }
      }

      callback();
    };
    return {
      suggestions: [],
      chatbox: "",
      tallView: false,
      voice: null,
      showWebCamDialog: false,
      showSuggestion: false,
      showPushToTalk: false,
      showingMoreMessages: false,

      listening: false,
      suggestSelect: -1,
      livechat_ongoing: true,
      lastEvent: null,
      emailFormVisible: false,
      webCamData: {
        senderId: this.$store.state.uid,
        recipientId: location.hostname,
      },
      form: {
        userEmail: "",
        chatHistory: "",
      },
      emailForm: {
        userEmail: [{ validator: validateEmail, trigger: "change" }],
      },
      loading: false,
      uploading: false,
      iOSSDK: this.$device.iOSSDK,
      triggerOption: this.$device.iOS || this.$device.iOSSDK ? "click" : "hover",
      isUploadable: false,
      lastUserMessage: "",
      lightBoxImage: null,
      toShowPreChatForm: false,
      toShowPostChatForm: false,
      isInternetConnectionAvailable: navigator.onLine,
      endButtonLoading: false,
      isShowScrollButton: false,
      lastScrollTop: 0,
      toShowHeaderMenu: false,
      isOpenTrendingTopic: false,
      isMinimizeVideo: false,
      selectedVideo: null,
      chatInputHeight: 38,
      maintainBannerHeight: 0,
      isShowMaintenanceBanner: true,
      botIsGenerateAnswer: false,
      signalRConnectionId: null,
      tempGenerateMessage: null,
      tempGenerateMessageId: null,
    };
  },
  computed: {
    isShowWidgetSizeSection() {
      const isShowExpandButton = !this.$device.Mobile && !this.tallViewSettings;
      const isShowMinimizeButton = !this.isMobileMode;
      return isShowExpandButton || isShowMinimizeButton;
    },
    iframeWebviewUrl() {
      const isPdf = _.includes(this.webviewUrl, "pdf=1");
      const userAgent = _.get(window, "navigator.userAgent", "");
      const isSafariMobile = userAgent.match(/iPad/i) || userAgent.match(/iPhone/i);

      return isPdf && isSafariMobile
        ? `https://drive.google.com/viewerng/viewer?embedded=true&url=${this.webviewUrl}`
        : this.webviewUrl;
    },
    /**
     * @description To activate tall view from kr_settings configurations
     * @return boolean;
     *  */
    tall: {
      get() {
        return window.kr_settings.tallView || this.tallView;
      },
      set(value) {
        this.tallView = value;
      },
    },
    isConnected() {
      return this.$store.state.isInitiating || this.$store.state.socketConnectionStatus;
    },
    clearButtonText() {
      return this.endSessionButtonEnabled ? "Clear Cache" : "Clear History";
    },
    isEnableNotification() {
      return this.$store.state.isEnabledNotification && Push.Permission.has();
    },
    tallViewSettings() {
      return window.kr_settings.tallView;
    },
    /**
     * @description to show more messages button
     * @return {boolean}
     */
    showMoreMessagesButton() {
      return (
        !this.$store.state.isInitiating &&
        !this.$store.state.newUser &&
        !this.$store.state.allPastMessagesFetched
      );
    },
    /**
     * @description to show load all messages notifier
     * @return {boolean}
     */
    showLoadAllMessagesButton() {
      return !this.$store.state.newUser && this.$store.state.allPastMessagesFetched;
    },
    /**
     * @description to show notif banner
     * @return {boolean}
     */
    showingNotifBanner() {
      return (
        this.$store.state.settings.enablePushNotification &&
        !this.isEnableNotification &&
        !this.isMobileMode
      );
    },
    /**
     * @description Element UI button ouline
     * @return {string}
     */
    buttonOutline() {
      switch (this.settings.buttonOutlineColor) {
        case "red":
          return "danger";
        case "blue":
          return "primary";
        case "green":
          return "success";
        default:
          return "primary";
      }
    },
    floatingButtonStyle() {
      return {
        position: "absolute",
        top: !this.isConnected || this.settings.liveChatStatus ? "52px" : "12px",
        right: "0px",
      };
    },
    /**
     * @description Chat window header style when mobile mode activated
     * @return object of style;
     *  */
    headerStyle() {
      const { isMobileModeDefault, settings } = this;

      const style = {
        backgroundColor: !isMobileModeDefault && settings.headerBackgroundColor,
        color: settings.headerFontColor || "white",
        borderBottom: !isMobileModeDefault && "5px solid " + settings.headerBorderColor,
        boxShadow: isMobileModeDefault && "0 0 0 0 rgb(0, 0, 0), 0 0 0 0 rgb(0, 0, 0)",
        height: isMobileModeDefault ? "0px !important" : undefined,
        padding: isMobileModeDefault ? "0px !important" : undefined,
      };

      if (this.isUiBetaEnabled)
        this.$set(style, "cursor", this.isWidgetReadyToDrag ? "grabbing" : "grab");

      return style;
    },
    /**
     * @description Any banner inside chat window custom style when mobile mode activated
     * @return object of style;
     *  */
    bannerStyle() {
      const { isMobileModeDefault } = this;

      return isMobileModeDefault
        ? { top: "0px", borderRadius: "10px 10px 4px 4px" }
        : this.showingNotifBanner
        ? { top: `${54 + 24}px` }
        : {};
    },
    /**
     * @description Chat bubbles section / main area of chat window position when mobile mode activated
     * @return object of style;
     *  */
    mainPositionStyle() {
      const { contentOffset, settings } = this;

      return {
        top: settings.liveChatStatus ? `${contentOffset + 40}px` : `${contentOffset}px`,
      };
    },
    /**
     * @description Checking persist header
     * @return boolean
     *  */
    persistHeader() {
      const params = new URLSearchParams(location.search);
      const persistHeader = params.get("header") === "true";

      return persistHeader;
    },
    /**
     * @description Checking user-source defined in url query
     * @return boolean
     *  */
    userSourceExist() {
      const params = new URLSearchParams(location.search);
      const userSourceExist = params.get("user-source");

      return !!userSourceExist;
    },
    /**
     * @description Checking mobile mode to launch automatically (not checking header)
     * @return boolean
     *  */
    isMobileMode() {
      const params = new URLSearchParams(location.search);
      const isMobileMode = params.get("mode") === "mobile";

      return isMobileMode;
    },
    /**
     * @description Checking mobile mode to launch automatically and checking header will be set or not
     * @return boolean
     *  */
    isMobileModeDefault() {
      return this.isMobileMode && !this.persistHeader;
    },
    /**
     * @description Offset for mobile and non-mobile purpose
     * @return number
     *  */
    contentOffset() {
      if (this.isMobileModeDefault) {
        return 0;
      }
      if (this.showingNotifBanner) {
        return 60 + 24;
      }

      return 60;
    },
    endSessionButtonEnabled() {
      return _.get(this.settings, "endSessionButton.enabled", false);
    },
    postChatFormEnabled() {
      return _.get(this.settings, "showPostChatForm.enabled", false);
    },
    chatboxHasValidLength() {
      const startAutocompleteCount = _.get(
        this.settings,
        "showSuggestions.toStartAutocomplete.startAutocompleteCount",
        3
      );
      const ignoreWhiteSpace = _.get(
        this.settings,
        "showSuggestions.toStartAutocomplete.ignoreWhiteSpace",
        false
      );
      const text = this.chatbox;
      let chatboxLength = text.length;
      if (ignoreWhiteSpace) {
        chatboxLength = this.stripWhiteSpace(text).length;
      }
      return text && chatboxLength >= startAutocompleteCount;
    },
    isMaintenanceBannerEnabled() {
      return _.get(this.settings, "maintenanceBanner.enabled", false);
    },
    showTextInput() {
      if (this.isTextInputDisabled) {
        return false;
      }
      if (this.showWebview) {
        return false;
      } else {
        return true;
      }
    },
    disableTextInput() {
      return (
        _.isEmpty(this.$store.state?.uid) ||
        _.get(this.lastBotMessage, "options.disableTextInput", false)
      );
    },
    chatWindowRight() {
      if (this.tall) {
        return "0";
      } else if (this.widgetPos.left) {
        return "auto";
      } else if (this.settings.size === "large") {
        return "130";
      } else {
        return "100";
      }
    },
    chatWindowBottom() {
      if (this.tall) {
        return "0px";
      } else {
        // Returning a blank string will use the CSS value
        return "";
      }
    },
    chatWindowHeight() {
      if (this.tall) {
        return "100%  ";
      } else {
        // Returning a blank string will use the CSS value
        return "";
      }
    },
    chatWindowTop() {
      return this.widgetPos.top || "auto";
    },
    chatWindowLeft() {
      return this.widgetPos.left || "auto";
    },
    showSideOptions() {
      const isShow =
        this.isFileUploadEnable ||
        this.settings.shareLocation ||
        this.settings.enableVoice ||
        this.settings.ocrButton;
      return isShow;
    },
    isFileUploadEnable() {
      const onlyAllowUploadWhenInLiveChat = this.settings.onlyAllowUploadWhenInLiveChat;
      if (onlyAllowUploadWhenInLiveChat) {
        return this.settings.fileUpload && this.settings.liveChatStatus && this.isUploadable;
      } else {
        return this.settings.fileUpload && this.isUploadable;
      }
    },
    showSendButton() {
      return this.chatbox.length > 0;
    },
    loadingText() {
      return this.loading ? "Forwarding chat history ..." : "Uploading files ...";
    },
    ...getters,
    sortedMessages() {
      for (const element of this.messages) {
        element.timestamp = new Date(element.timestamp);
      }
      const result = _.orderBy(this.messages, ["timestamp"], ["asc"]);
      return result;
    },
    agents() {
      const agents = this.messages.map((message) => {
        return _.get(message, "data.meta.name", null);
      });
      const uniqAgents = _.uniqBy(agents);
      return _.compact(uniqAgents);
    },
    messageSize() {
      return this.sortedMessages.length;
    },
    firstMessage() {
      return _.first(this.sortedMessages) || {};
    },
    lastMessage() {
      return _.last(this.sortedMessages) || {};
    },
    lastBotMessage() {
      const messages = this.sortedMessages.filter((message) => !_.isEmpty(message.data.content));
      return _.findLast(messages, { type: "reply" }) || {};
    },
    disablePointerStyle() {
      return this.lastEvent === "keydown" && !this.$device.Mobile ? "pointer-events:none;" : "";
    },
    typingIndicatorUrl() {
      const webchatUrl = _.get(window, "appConfig.WEBCHAT_URL");
      if (webchatUrl) {
        return new URL("typing_indicator.gif", webchatUrl).href;
      }

      return "https://keyreply.blob.core.windows.net/files/images/typing_indicator.gif";
    },
    notificationSoundUrl() {
      const webchatUrl = _.get(window, "appConfig.WEBCHAT_URL");
      if (webchatUrl) {
        return new URL("sound/notification.ogg", webchatUrl).href;
      }

      return "https://keyreply.blob.core.windows.net/files/sound/notification.ogg";
    },
    showHistoryDownload() {
      return _.get(this.settings, "showHistoryDownload", true);
    },
    showClearCloneState() {
      const params = getParamsFromUrl();
      return params.get("isSimulated");
    },
    sessionButtonReady() {
      // return false;
      return this.endSessionButtonEnabled && this.$store.state.sessionButtonReady;
    },
    sessionEnded: {
      get() {
        return this.$store.state.sessionEnded;
      },
      set(val) {
        this.$store.commit("SET_END_SESSION", val);
      },
    },
    filteredXSSMessage() {
      const textBeforeXSSFilter = this.chatbox.trim();
      return filterXSS(textBeforeXSSFilter, {
        whiteList: {}, // empty, means filter out all tags
        stripIgnoreTag: true, // filter out all HTML not in the whitelist
        stripIgnoreTagBody: ["script"], // the script tag is a special case, we need to filter out its content
      });
    },
    hideSuggestion() {
      return _.get(this.lastBotMessage, "data.meta.hideSuggestions", false);
    },
    countdownLimitLabel() {
      const countdownType = this.settings.charLimit.countdownType;
      const limit = this.settings.charLimit.limit;
      return countdownType === "increase"
        ? `${this.chatbox.length}/${limit}`
        : limit - this.chatbox.length;
    },
    typingsLabel() {
      const lang = _.get(this, "lastBotMessage.data.meta.lang", "default");
      const result = parseLanguageBasedLabel(
        this.settings.languageBasedLabels,
        "textareaTypingsPlaceholder",
        lang,
        "Type a message..."
      );
      return result;
    },
    chooseOptionLabel() {
      const lang = _.get(this, "lastBotMessage.data.meta.lang", "default");
      const result = parseLanguageBasedLabel(
        this.settings.languageBasedLabels,
        "textareaChoicesPlaceholder",
        lang,
        "Choose an option above..."
      );
      return result;
    },
    isUserTyping() {
      return this.$refs["textbox"].focused;
    },
    headerModalIconColor() {
      return this.$store.getters.settings.headerModalIconColor;
    },
    headerModalIconColorMobile() {
      return (
        this.$store.getters.settings.headerModalIconColorMobile ||
        this.$store.getters.settings.headerBackgroundColor
      );
    },
    backgroundStyle() {
      return {
        backgroundColor: this.$store.getters.settings.userMainBackgroundColor,
      };
    },
    titleStyle() {
      return {
        color: this.$store.getters.settings.userMainTitleColor,
      };
    },
    iconStyle() {
      return {
        backgroundColor: this.$store.getters.settings.widgetIconColor,
        color: this.$store.getters.settings.widgetIconTextColor,
      };
    },
    widgetElement() {
      return this.$refs.keyReplyChatWindow.$el;
    },
    lastVideo() {
      if (this.sortedMessages.length === 0) return false;
      const botMessages = this.sortedMessages.filter((message) => message.type === "reply");
      const lastEl = _.last(botMessages) || {};
      return lastEl?.data?.content[0]?.options?.mode === "video" ? lastEl : false;
    },
    showTextInputVideo() {
      if (this.lastVideo || this.selectedVideo) {
        return this.isMinimizeVideo;
      }
      return true;
    },
    chatboxBorderStyle() {
      if (!this.isUiBetaEnabled) {
        return "10px";
      }
      return this.isUiBetaEnabled && this.chatInputHeight > 50 ? "10px" : "50px";
    },
    isLastMessageEndingChat() {
      return (
        this.lastMessage?.type === "postback" &&
        this.lastMessage?.data?.content?.[0]?.text === "End Live Chat"
      );
    },
  },
  watch: {
    chatbox(newText, oldText) {
      if (newText) {
        this.showSuggestion = !this.hideSuggestion;
        this.suggestSelect = -1;
      }
      if (typeof newText === "object") {
        newText = "";
      }
      if (typeof oldText === "object") {
        oldText = "";
      }
      if (this.settings.liveChatStatus) {
        this.updateTypingIndicator(newText);
      }
      this.debounceSuggestionListing(newText);
    },
    lastMessage(newText, oldText) {
      if (this.toShowPreChatForm) return;
      if (this.toShowPostChatForm) return;
      if (newText !== oldText) {
        this.$nextTick(() => {
          this.scrollToBottom();
          this.$refs.textbox.focus();
        });
      }
    },
  },
  beforeMount() {
    window.onmessage = (e) => {
      if (e.data === "downloadcompleted") {
        this.closeWebView();
      }
    };
    window.addEventListener("message", this.crossOriginMessageHandler, false);
    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("touchmove", this.onTouchMove);
    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("touchmove", this.onTouchMove);
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
  async mounted() {
    if (Push.Permission.has()) {
      this.$store.commit("SET_NOTIFICATION_PERMISSION", true);
    }

    this.$emit("launcherclicked");

    if (this.settings.tabs && !_.isEmpty(this.settings.tabs)) {
      if (this.settings.tabs[0].url !== "main") {
        //FIXME Without delay, the UI will go into a crash loop
        _.delay(() => {
          this.$store.dispatch("SET_WEBVIEWURL", this.settings.tabs[0].url);
          this.$store.dispatch("SET_SHOW_WEBVIEW", true);
          // this.$refs.iframe.style = this.settings.tabs[0].style && this.settings.tabs[0].style !== "" ? this.settings.tabs[0].style : "width:100%;height:100%;"
          this.$refs.iframe.style =
            this.settings.tabsIframeStyle && this.settings.tabsIframeStyle !== ""
              ? this.settings.tabsIframeStyle
              : "width:100%;height:100%;";
        }, 100);
      }
    }

    if (this.settings.fileUpload) {
      this.isFileUploadable();
    }
    this.resetPreChatFormVisibilityBySetting();

    // Set maintain banner height variable if enabled
    if (this.isMaintenanceBannerEnabled) {
      this.setMaintainBannerHeight();
    }
  },
  methods: {
    onTouchMove() {
      this.lastEvent = "touchmove";
      if (this.$refs.textbox) {
        this.$refs.textbox.blur();
      }
    },
    onMouseMove() {
      this.lastEvent = "mousemove";
    },
    onKeyDown(e) {
      this.lastEvent = "keydown";
      if (this.showSuggestion === false || this.suggestions.length === 0) {
        return;
      }
      const code = e.keyCode ? e.keyCode : e.which;
      const upKey = code === 38;
      const downKey = code === 40;
      const escKey = code === 27;

      if (upKey) {
        this.changeSuggestion(-1);
        e.preventDefault();
      } else if (downKey) {
        this.changeSuggestion(1);
        e.preventDefault();
      } else if (escKey) {
        this.toggleSuggestion(false);
        this.suggestSelect = -1;
      }
    },
    stripWhiteSpace(text) {
      return text.replace(/\s/g, "");
    },
    async fetchHistory() {
      this.showingMoreMessages = true;
      await this.$store.dispatch("FETCH_HISTORY", { fetchMore: true });
      this.showingMoreMessages = false;
    },
    focusInput() {
      this.toggleSuggestion(true);
      this.$store.commit("CLEAR_UNREAD_MESSAGE_COUNT");
    },
    requestNotificationPermission() {
      this.$store.dispatch("REQUEST_NOTIFICATION_PERMISSION");
    },
    //emitted by PretChatForm.vue component
    setShowPreChatForm(toShow) {
      this.toShowPreChatForm = toShow === true;
    },
    //emitted by PostChatForm.vue component
    setShowPostChatForm(toShow) {
      this.toShowPostChatForm = toShow === true;
    },
    debounceSuggestionListing: _.debounce(function (newText) {
      if (this.settings.suggestions) {
        if (newText.length === 0) {
          const DEFAULT_SUGGESTIONS = _.filter(
            this.settings.suggestions,
            (suggestion) => suggestion.default === true
          ) || [{ value: "What can I ask you?" }];

          return DEFAULT_SUGGESTIONS;
        } else {
          const allSuggestions = this.$store.state.fuse.search(newText);
          const suggestionsLimit = _.get(this.settings, "showSuggestions.suggestionsLimit", 4);
          const topFewSuggestions = _.slice(allSuggestions, 0, suggestionsLimit);
          const singleLanguageResult = _.uniqBy(topFewSuggestions, "language").length == 1;
          this.suggestions = singleLanguageResult ? topFewSuggestions : [];
        }
      }
    }, 500),
    showQuickReply(currentMessage, index) {
      const lastMessage = _.last(this.sortedMessages);
      const isLastMessage = currentMessage?.id === lastMessage?.id;
      const displayMode = _.get(currentMessage, "options.quickReplyDisplayMode", "default");

      return (
        isLastMessage ||
        displayMode === "always" ||
        (displayMode === "default" && this.settings.alwaysShowQuickReply)
      );
    },
    sendMessage: _.debounce(
      function (payload) {
        this.$store.dispatch("SEND_MESSAGE", payload).catch((error) => {
          const errType = error?.response?.data?.error;
          const message =
            errType === "[RequestValidation]"
              ? "Your message could not be sent due to a validation error. Please check your input."
              : "Failed to send your message, please try again later.";
          this.$notify.error({
            title: "Error",
            message,
            duration: 2000,
            position: "bottom-right",
            offset: this.getNotifyBoxOffset(),
            customClass: this.getNotifyBoxCustomClass(),
          });
        });
      },
      200,
      { leading: true }
    ),
    updateTypingIndicator: _.debounce(
      function (text) {
        let status = false;
        if (text) {
          status = true;
        }
        this.$store.dispatch("UPDATE_AGENT_TYPING_INDICATOR", status);
      },
      500,
      { leading: true }
    ),
    resizeTextArea() {
      const textarea = this.$refs.textbox.$refs.textarea;
      textarea.style.height = "38px";
      const textAreaHeight = Math.max(textarea.scrollHeight, 38);
      textarea.style.height = textAreaHeight - 20 + "px";
      this.chatInputHeight = textAreaHeight - 20;
    },
    footerButtonClick(command) {
      switch (command) {
        case "upload document":
          this.$refs.uploader.onUpload("document");
          break;
        case "upload photo":
          this.$refs.uploader.onUpload("image");
          break;
        case "ocr":
          this.showWebCamDialog = true;
          break;
        case "share location":
          this.sendLocationMessage();
          break;
        case "speech to text":
          this.toggleVoice();
          break;
        default:
          return;
      }
    },
    crossOriginMessageHandler(evt) {
      const messageData = evt.data;
      const isOriginAcceptable = _.find(this.settings.tabs, (o) => o.url.includes(evt.origin));
      if (typeof messageData === "string" && isOriginAcceptable) {
        // Switching to KR chat
        this.$refs.tabs.currentName = "main";
        this.$store.dispatch("SET_SHOW_WEBVIEW", false);
        this.sendMessage({ text: messageData });
        // this.$store.dispatch("SEND_MESSAGE", { text: evt.data });
      }
      if (typeof messageData === "object") {
        try {
          const event = _.get(messageData, "krDispatcher.event");
          const payload = _.get(messageData, "krDispatcher.payload");
          const close = _.get(messageData, "krDispatcher.close");
          window.$keyreply.dispatch(event, payload);
          if (close) {
            window.$keyreply.dispatch("CLOSE_WEBVIEW");
          }
        } catch (error) {
          return;
        }
      }
    },
    isFileUploadable() {
      if (!this.settings.fileUpload) {
        console.error("file upload is not enabled");
        this.isUploadable = false;
        return;
      }

      this.isUploadable = true;

      //   this.$store
      //     .dispatch("VERIFY_CORS_SAS_URL", { url })
      //     .then((response) => {
      //       this.isUploadable = true;
      //     })
      //     .catch((error) => {
      //       this.isUploadable = false;
      //     });

      // FIXME: @Dhoni To be deprecated!
      // // Upload to temp blob
      // this.$store
      //   .dispatch("UPLOAD_FILE", {
      //     sasUrls: sasUrls.data,
      //     formData,
      //     files: [testFile],
      //   })
      //   .then(async (response) => {
      //     // display file as message to client
      //     this.isUploadable = true;
      //   })
      //   .catch((error) => {
      //     this.isUploadable = false;
      //     this.pendingUploadFiles = [];
      //   });
      // });
    },
    async renewSession() {
      await this.$store.dispatch("START");
    },
    emailFormSubmit(event) {
      if (event) {
        event.preventDefault();
      }
      this.$refs["emailForm"].validate((valid) => {
        if (valid) {
          let loadingClosed = false;
          this.loading = true;

          const offset = this.getNotifyBoxOffset();
          const customClass = this.getNotifyBoxCustomClass();

          setTimeout(() => {
            if (!loadingClosed) {
              loadingClosed = true;
              this.$notify({
                title: "Error",
                message: "Request to forward chat history time out.",
                duration: 2000,
                type: "error",
                position: "bottom-right",
                offset,
                customClass,
              });
              this.loading = false;
            }
          }, 15000);

          this.$store
            .dispatch("EMAIL_HISTORY", this.form)
            .then((result) => {
              loadingClosed = true;
              this.$notify.success({
                title: "Success",
                message: "Chat history sent!",
                duration: 2000,
                position: "bottom-right",
                offset,
                customClass,
              });
              this.loading = false;
              this.form.userEmail = "";
            })
            .catch((error) => {
              if (!loadingClosed) {
                loadingClosed = true;
                this.$notify.error({
                  title: "Error",
                  message: "Error forwarding chat history.",
                  duration: 2000,
                  position: "bottom-right",
                  offset,
                  customClass,
                });
                this.loading = false;
              }
            });
        } else {
          return false;
        }
      });
      this.emailFormVisible = false;
    },
    tapHandler(e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      this.closeWindow();
    },
    closeWindow() {
      if (this.iOSSDK) {
        const url = "keyreplysdk://?alert=close";
        window.location.href = url;
      }

      this.$store.dispatch("CLOSE_CHAT_WINDOW");
    },
    handleCommand(command) {
      switch (command) {
        case "clearCache":
          this.clearCache();
          break;
        case "downloadHistory":
          this.downloadHistory();
          break;
        case "clearCloneState":
          this.clearCloneState();
          break;
        case "endChat":
          this.endChat();
          break;
        case "endSession":
          this.endSession();
          break;
      }
    },
    handleTabClick(clickedTab) {
      if (clickedTab.name === "main") {
        this.$store.dispatch("SET_SHOW_WEBVIEW", false);
      } else {
        this.$store.dispatch("SET_SHOW_WEBVIEW", true);
        if (clickedTab.name !== this.$store.state.webviewUrl) {
          this.$store.dispatch("SET_WEBVIEWURL", clickedTab.name);
          const tab = _.find(this.settings.tabs, (tab) => tab.url === clickedTab.name);
          if (tab) {
            const style = _.get(tab, "style");
            if (style) {
              this.$refs.iframe.style = style;
            } else {
              this.$refs.iframe.style = "width:100%;height:100%";
            }
          }
        }
      }
    },
    closeWebView() {
      this.$store.dispatch("CLOSE_WEBVIEW");
      this.$store.dispatch("SET_SHOW_WEBVIEW", false);
      this.$store.dispatch("SET_RETURN_TO_CHAT", false);
      this.$nextTick(this.scrollToBottom);
    },
    allowDrop(ev) {
      ev.preventDefault();
    },
    resetPreChatFormVisibilityBySetting() {
      const toShowPreChatForm = _.get(this.settings, "showPreChatForm.enabled", false);
      this.setShowPreChatForm(toShowPreChatForm);
    },
    async onPreChatFormSuccess(payload) {
      await this._start({ with_register: false, with_login: false });
    },
    resetPostChatFormVisibilityBySetting() {
      this.setShowPostChatForm(this.postChatFormEnabled);
    },
    async _stop({ with_clear_cache = false }) {
      await this.$store.dispatch("STOP_LIVECHAT");
      await this.$store.dispatch("LOGOUT");
      if (with_clear_cache) {
        await this.$store.dispatch("CLEAR_CACHE");
      }
    },
    async _start({ with_register = false, with_login = false }) {
      if (with_register) {
        await this.$store.dispatch("REGISTER");
      }
      if (with_login) {
        await this.$store.dispatch("LOGIN");
      }
      await this.$store.dispatch("LISTEN");
      this.$store.commit("SET_GREETED", true);
      await this.$store.dispatch("SEND_START_MESSAGE");
      await this.$store.dispatch("DISABLE_TEXT_INPUT", false);
    },
    async clearCache() {
      const confirm = window.confirm(
        `Clear ${this.endSessionButtonEnabled ? "cache" : "history"}?`
      );
      if (confirm) {
        this.closeHeaderMenu();
        const defaultLanguage = this.$store.state.settings.defaultLanguage || "ms";
        localStorage.setItem("currentLanguage", defaultLanguage);
        this.$store.commit("SET_CURRENT_LANGUAGE", defaultLanguage);
        this.isOpenTrendingTopic = false;
        await this._stop({ with_clear_cache: true });
        await this._start({ with_register: true, with_login: true });
        //reset pre chat form
        this.resetPreChatFormVisibilityBySetting();
        //reset video
        this.resetSelectedVideo();
        this.sessionEnded = false;
      }
    },
    async clearCloneState() {
      await this.$store.dispatch("CLEAR_CLONE_STATE");
    },
    async downloadHistory() {
      let idtext = `\r\nuid: ${this.$store.state.uid}`;
      const eceActivityId = this.$store.state.settings.eceActivityId;
      if (eceActivityId) {
        idtext = `\r\nactivityId: ${eceActivityId}`;
      }
      let text = `---- User Info ----
      ${idtext}
			\r\ntimestamp_generated: ${dayjs().format("DD-MM-YYYY HH:mm:ss")}
			${!this.userSourceExist ? `\r\nurl: ${window.location.href.split("?")[0]}` : ""}
			\r\n
			\r\n---- Chat history ----
      \r\n`;
      const chat = _.sortBy(this.$store.state.messages, (message) => message.timestamp);
      _.each(chat, (message) => {
        text += dayjs(message.timestamp).format("DD-MM-YYYY HH:mm:ss");
        let prompt = " bot: ";
        if (message.type === "message" || message.type === "postback") {
          prompt = " user: ";
        } else if (message.type === "agent") {
          prompt = " agent: ";
        }
        text += prompt;
        text += _.map(message.data.content, (content) => {
          if (!_.isEmpty(content.text)) {
            return content.text;
          } else if (!_.isEmpty(content.files)) {
            return "[File Content]";
          } else if (!_.isEmpty(content.images)) {
            return "[Image Content]";
          } else {
            return "user connected";
          }
        }).join(" | ");
        text += "\r\n";
        // optionally hide agent name
        if (this.$store.state.settings.historyHideAgentName) {
          text = text.replace(/bot: (.+) has joined the chat\./, "bot: Agent has joined the chat.");
        }
      });

      const useEmailDelivery = _.get(this.settings, "historyDeliveryMode") == "EMAIL";
      if (useEmailDelivery || this.iOSSDK || this.isMobileMode) {
        // prompt for email address and call endpoint to send email
        this.emailFormVisible = true;
        this.$nextTick(() => {
          this.$refs["userEmail"].focus();
        });
        this.form.chatHistory = text.replace(/\n/gm, "<br>");
      } else if (this.settings.historyDeliveryMode === "PDF") {
        const pdfDoc = await PDFDocument.create();
        pdfDoc.registerFontkit(fontkit);
        const courierFont = await pdfDoc.embedFont(StandardFonts.Courier);

        let fontUrl = "https://keyreply.blob.core.windows.net/files/fonts/NotoSans-Regular.ttf";
        const webchatUrl = _.get(window, "appConfig.WEBCHAT_URL");
        if (webchatUrl) {
          fontUrl = new URL("fonts/NotoSans-Regular.ttf", webchatUrl).href;
        }
        const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
        const font = await pdfDoc.embedFont(fontBytes);

        const x = 32;
        const lineHeight = 8;
        const maxCharsPerLine = 125;

        let page = pdfDoc.addPage();
        let { width, height } = page.getSize();
        let y = height - 32;

        const lines = text.split("\n");
        for (let i = 0; i < lines.length; i++) {
          let words = lines[i].split(" ");
          let line = "";

          while (words.length > 0) {
            if (y < lineHeight * 2) {
              // If there's not enough space for another line, add a new page
              page = pdfDoc.addPage();
              ({ width, height } = page.getSize());
              y = height - 32;
            }
            while (words.length > 0 && (line + words[0]).length < maxCharsPerLine) {
              line += words.shift() + " ";
            }
            page.drawText(line.trim(), { x, y, size: lineHeight, font, color: rgb(0, 0.53, 0.71) });
            y -= lineHeight;
            if (line.trim() !== "") {
              y -= lineHeight; // Subtract an extra lineHeight value when the line is not empty
            }
            line = "";
          }
        }

        const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });

        saveAs(pdfDataUri, "chat_history.pdf");
      } else {
        var blob = new Blob([text], { type: "text/plain;charset=utf-8" });
        saveAs(blob, "chat_history.txt");
      }
    },
    endChat() {
      // hide pre-chat form when
      // end chat triggered while showing pre-chat form
      this.setShowPreChatForm(false);
      this.resetPostChatFormVisibilityBySetting();
    },
    toggleSuggestion(tempToggle, delay) {
      const toggle = this.hideSuggestion ? false : tempToggle;
      if (delay) {
        setTimeout(() => {
          this.showSuggestion = toggle;
        }, delay);
      } else {
        this.showSuggestion = toggle;
      }
    },
    scrollToBottom() {
      const box = this.$refs.window;
      if (box) {
        //TODO offset by bubble height.

        box.$el.scrollTop = box.$el.scrollHeight;
      }
    },
    sendLocationMessage() {
      try {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            var crd = pos.coords;
            this.sendMessage({
              location: { long: crd.longitude, lat: crd.latitude },
            });
            // this.$store.dispatch("SEND_MESSAGE", {
            //   location: { long: crd.longitude, lat: crd.latitude }
            // });
          },
          (error) => {},
          {
            enableHighAccuracy: false,
            timeout: 10000,
            maximumAge: 1000,
          }
        );

        return;
      } catch (error) {
        // console.warn("No Geolocation");
      }
    },
    sendPostback(button) {
      if (!this.isInternetConnectionAvailable) {
        this.$notify.error({
          title: "Error",
          message: "Your internet connection is not working, please try again later.",
          duration: 2000,
          position: "bottom-right",
          offset: this.getNotifyBoxOffset(),
          customClass: this.getNotifyBoxCustomClass(),
        });
        return;
      }

      let {
        text,
        event,
        data,
        url,
        phone,
        isLocation,
        popup,
        type,
        options = {},
        trendingText = "",
        intentName = "",
        trackUrl,
        $event,
      } = button;
      if (event === "set") {
        const language = data?.data?.entities_last?.languageDetect;
        if (language) {
          this.$store.commit("SET_CURRENT_LANGUAGE", language);
          localStorage.setItem("currentLanguage", language);
        }
      }
      button.active = true;
      if (typeof data === "string" || typeof url === "string") {
        this.$store.dispatch("CLICK_INTENT", { text, event, type, data, url, intentName });
      }
      // Utilize browser's navigator
      if (event === "$location" || isLocation) {
        this.sendLocationMessage();
      }
      if (event === "$tab") {
        // For NGEE ANN POLY
        const iframe = this.$refs.iframe;
        this.$store.dispatch("SET_SHOW_WEBVIEW", true);
        if (this.$store.state.webviewUrl === data) {
          this.$refs.tabs.currentName = data;
          iframe.contentWindow.postMessage(this.$data.lastUserMessage, data);
          return;
        }

        this.$store.dispatch("SET_WEBVIEWURL", data);
        const tab = _.find(this.settings.tabs, (tab) => tab.url === data);
        if (tab) {
          const style = _.get(tab, "style");
          if (style) {
            this.$refs.iframe.style = style;
          } else {
            this.$refs.iframe.style = "width:100%;height:100%";
          }
          this.$refs.tabs.currentName = data;

          this.$refs.iframe.onload = () => {
            setTimeout(() => {
              this.$refs.iframe.contentWindow.postMessage(this.$data.lastUserMessage, data);
            }, 2000);
          };
        }
      }

      if (event === "$display") {
        // case: prevent opening multiple pages if the button is within an anchor tag
        if ($event && typeof $event.preventDefault === "function") {
          $event.preventDefault();
        }

        // Opens an iframe
        if (data.type === "iframe" || data.type === "popup") {
          // Show Lightbox
          this.$parent.lightboxContent = data.url;
          // this.$parent.showLightbox = true;
          this.$store.commit("OPEN_DIALOG");
          return;
        }

        // Opens a new tab with a button click
        if (data.type === "tab") {
          let win = window.open(data.url, "_blank");
          if (win) {
            win.focus();
          }
        }

        // Opens url within the chatbot
        if (data.type === "inline") {
          // Show inline
          const dataURL = url || data?.url;
          if (_.includes(dataURL, "download=1")) {
            window.open(
              dataURL,
              "targetWindow",
              `toolbar=no,
              location=no,
              status=no,
              menubar=no,
              scrollbars=yes,
              resizable=yes,
              width=400,
              height=600`
            );
          } else {
            const hideBackButton = _.get(data, "hideBackButton", false);
            this.$store.dispatch("SET_WEBVIEWURL", data.url);
            this.$store.dispatch("SET_SHOW_WEBVIEW", true);
            this.$store.dispatch("SET_RETURN_TO_CHAT", !hideBackButton);
          }
          return;
        }
      }

      if (event === "$zopim") {
        if (window.$zopim) {
          let action = _.get(window.$zopim, data);
          if (action && typeof action === "function") {
            action();
          }
        }
      }

      if (event === "$navigate") {
        // data
        // document.getElementById('iframe').attributes.s
      } else {
        if (url) {
          // if (url.includes("mailto")) {
          //   url = url.match(/^(http(s)?:)?\/\//i) ? url : url;
          // } else {
          //   url = url.match(/^(http(s)?:)?\/\//i) ? url : "//" + url;
          // }

          // const openNewTab = () => {
          //   // Open a new tab
          //   let win = window.open(url, "_blank");
          //   if (win) {
          //     win.focus();
          //   } else {
          //     // console.log("The browser has blocked popup");
          //     // location.href = url;
          //   }
          // };
          // const openInline = () => {
          //   this.$store.state.webviewUrl = url;
          //   return;
          // };

          // const openCurrentWindow = () => {
          //   location.href = url;
          // };

          // // if (iframe) {
          // //   // Show Lightbox
          // //   this.$parent.lightboxContent = url;
          // //   this.$store.commit("OPEN_DIALOG");
          // //   return;
          // // }

          // // Need to decide when to open in different tab
          // if (popup === true) {
          //   // open new window
          //   openNewTab();
          // } else if (popup === false) {
          //   // stay current window
          //   openCurrentWindow();
          // } else if (this.settings.popup) {
          //   // if button-specific popup doesn't exist, use global "popup" setting
          //   // open new window
          //   openNewTab();
          // } else if (this.settings.inline) {
          //   openInline();
          // } else {
          //   // if button-specific popup doesn't exist, use global "popup" setting
          //   // stay current window
          //   openCurrentWindow();
          // }
          if (trackUrl) {
            this.$store.dispatch("SEND_POSTBACK", {
              event: "track_url",
              data: url,
              text: "",
              options: {},
            });
          }
        } else if (phone) {
          // eslint-disable-next-line
          const phone_regrex =
            /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))$/;
          const valid_number = phone_regrex.exec(phone);
          if (valid_number) {
            const action = type === "phone" ? "tel:" : "sms:";
            var call = window.open(action + phone);
            // TODO: Chrome on desktop didn't automatically open facetime
            // if (call) {
            //	 setTimeout(() => {
            //		 call.close();
            //	 }, 500);
            // }
            this.$store.dispatch("SEND_POSTBACK", {
              event: type === "phone" ? "track_phone" : "track_sms",
              data: phone,
              text: "",
              options: {},
            });
          } else {
            // console.log("Please ensure phone number is in correct format");
          }
        } else {
          this.$store.dispatch("SEND_POSTBACK", { event, data, text, options, trendingText });
        }

        if (data && data.clientEvent && data.clientData) {
          if (data.clientEvent) {
            if (data.clientEvent === "SHOW_TEXTBAR") {
              this.$store.commit("SHOW_TEXTBAR", data.eventData);
            }
          }
        }
      }

      // this.$refs.textbox.focus();
    },
    send(event) {
      if (event) {
        event.preventDefault();
        if (event.shiftKey) {
          this.chatbox += "\n";
          return;
        }
        event.target.rows = 1;
      }
      if (!this.isInternetConnectionAvailable) {
        this.$notify.error({
          title: "Error",
          message: "Your internet connection is not working, please try again later.",
          duration: 2000,
          position: "bottom-right",
          offset: this.getNotifyBoxOffset(),
          customClass: this.getNotifyBoxCustomClass(),
        });
        return;
      }

      if (
        this.showSuggestion &&
        this.suggestions &&
        this.suggestions.length > 0 &&
        this.suggestSelect > -1
      ) {
        const suggestion = this.suggestions[this.suggestSelect];
        if (!suggestion) return;
        this.chatbox = _.get(suggestion, "item.value", "");
      }
      const text = this.filteredXSSMessage;
      if (text) {
        this.$data.lastUserMessage = text;
        this.sendMessage({ text });
        // this.$store.dispatch("SEND_MESSAGE", );
        this.chatbox = "";
        if (!this.showPushToTalk) {
          this.$refs.textbox.suggestions = [];
          this.$refs.textbox.focus();
        }

        // Fix iOS Autocorrect Bug.
        _.delay(() => (this.chatbox = new String()), 100);
      }
      this.selectedVideo = null;
    },
    async endLiveChat() {
      this.sendPostback({
        text: "End Live Chat",
        event: "handover_stop",
        data: "user",
      });
      await this.$store.dispatch("STOP_LIVECHAT");
    },
    async endSession() {
      this.endButtonLoading = true;
      if (this.settings.liveChatStatus) {
        await this.endLiveChat();
      } else if (this.postChatFormEnabled) {
        this.endChat();
      } else {
        await this.$store.dispatch("END_SESSION");
        this.sessionEnded = true;
      }
      this.endButtonLoading = false;
    },
    async startSession() {
      this.endButtonLoading = true;
      await this._stop({});
      this.sessionEnded = false;
      this.endButtonLoading = false;
      await this._start({ with_register: false, with_login: true });
    },
    changeSuggestion(change) {
      let currentSelection = this.suggestSelect;
      let targetSelection = currentSelection + change;

      if (targetSelection < -1) {
        this.suggestSelect = this.suggestions.length - 1;
      } else if (targetSelection > this.suggestions.length - 1) {
        this.suggestSelect = -1;
      } else {
        this.suggestSelect = targetSelection;
      }
    },
    setSuggestIndex(index) {
      this.suggestSelect = index;
    },

    // Speech to text

    toggleVoice() {
      this.showPushToTalk = !this.showPushToTalk;

      if (this.showPushToTalk) {
        this.showSuggestion = false;

        this.voice = this.$listen((text, complete) => {
          if (text) {
            this.chatbox = text.trim();
          }
        });
      } else {
        this.showSuggestion = true;
      }
    },

    startListen() {
      this.listening = true;
      this.voice.start();
    },

    stopListen() {
      if (this.listening) {
        this.listening = false;
        this.voice.stop();
        this.send();

        setTimeout(() => {
          this.chatbox = "";
        }, 500);
      }
    },

    speak(text, event) {
      // if (this.voice) {
      if (event.target.tagName !== "A") {
        this.$speak(text);
      }
      // }
    },

    updateOnlineStatus(e) {
      this.isInternetConnectionAvailable = e.type === "online";
    },
    getNotifyBoxOffset() {
      return this.isMobileMode ? 40 : 600;
    },
    getNotifyBoxCustomClass() {
      return window.innerWidth < 350 ? "el-notification__small" : "";
    },
    scrollToStickyMenu() {
      const language = this.stickyMenuLanguage;
      const stickyMenu = _.last(document.querySelectorAll(`.sticky-menu-${language}`));
      const webchatBody = document.getElementById("keyreply-panel-body");
      webchatBody.scrollTo({
        top: stickyMenu.offsetTop - 50,
        behavior: "smooth",
      });
    },
    onWidgetScroll(e) {
      const widgetScrollTopPos = e.target.scrollTop;
      if (widgetScrollTopPos > this.lastScrollTop) {
        this.isShowScrollButton = false;
      } else {
        if (!_.isEmpty(this.stickyMenu)) {
          const language = this.stickyMenuLanguage;
          const stickyMenuElement = _.last(document.querySelectorAll(`.sticky-menu-${language}`));
          const scrollPosition = e.target.scrollTop + e.target.clientHeight;
          if (stickyMenuElement && scrollPosition > stickyMenuElement.offsetTop) {
            this.isShowScrollButton = !this.isNodeVisibleWhenScroll(stickyMenuElement, e.target);
          } else {
            this.isShowScrollButton = false;
          }
        }
      }
      this.lastScrollTop = widgetScrollTopPos <= 0 ? 0 : widgetScrollTopPos;
    },
    isNodeVisibleWhenScroll(ele, container) {
      const eleTop = ele.offsetTop;
      const eleBottom = eleTop + ele.clientHeight;

      const containerTop = container.scrollTop;
      const containerBottom = containerTop + container.clientHeight;

      // The element is fully visible in the container
      return (
        (eleTop >= containerTop && eleBottom <= containerBottom) ||
        // Some part of the element is visible in the container
        (eleTop < containerTop && containerTop < eleBottom) ||
        (eleTop < containerBottom && containerBottom < eleBottom)
      );
    },
    openHeaderMenu() {
      this.toShowHeaderMenu = true;
    },
    closeHeaderMenu() {
      this.toShowHeaderMenu = false;
    },
    handleChangeWidgetSize(value) {
      this.widgetElement.style.removeProperty("left");
      this.widgetElement.style.removeProperty("top");
      this.tall = value;
    },
    handleTrendingTopicList(isOpen = false) {
      this.isOpenTrendingTopic = !this.isOpenTrendingTopic;
    },
    handleMinimizeVideo() {
      this.isMinimizeVideo = true;
      this.selectedVideo = null;
      this.$nextTick(this.scrollToBottom);
    },
    handleExpandVideo(value) {
      this.selectedVideo = value;
      this.isMinimizeVideo = false;
    },
    resetSelectedVideo() {
      this.selectedVideo = null;
      this.$nextTick(this.scrollToBottom);
    },
    handleTypingMessage(value) {
      this.chatbox = value;
    },
    setMaintainBannerHeight() {
      const banner = document.getElementsByClassName("keyreply-maintenance-banner");
      if (banner.length > 0) {
        const bannerHeight = banner[0].offsetHeight;
        this.maintainBannerHeight = bannerHeight * 0.7;
      }
    },
    closeMaintenanceBanner() {
      this.isShowMaintenanceBanner = false;
    },
  },
  async created() {
    window.addEventListener("visibilitychange", (event) => {
      if (!document.hidden) {
        this.$store.commit("CLEAR_UNREAD_MESSAGE_COUNT");
      }
    });
    const hubConnection = signalRHubConnection(this.settings.semanticServiceUrl);

    hubConnection.on("StartReceiveMessage", () => {
      this.tempGenerateMessageId = uuid();
      this.tempGenerateMessage = {
        data: {
          content: [
            {
              text: "",
            },
          ],
        },
        id: this.tempGenerateMessageId,
        timestamp: new Date().toISOString(),
      };
      this.messages.push(this.tempGenerateMessage);
      this.botIsGenerateAnswer = true;
    });

    hubConnection.on("ReceiveMessage", (message) => {
      if (message) {
        const currentMessage = _.find(this.messages, (o) => o.id === this.tempGenerateMessageId);
        const currentMessageContent = currentMessage.data.content[0].text;
        const currentMessageIndex = _.findIndex(
          this.messages,
          (o) => o.id === this.tempGenerateMessageId
        );
        const newMessage = currentMessageContent.concat(message);
        currentMessage.data.content[0].text = newMessage;

        this.messages[currentMessageIndex] = currentMessage;
        this.$nextTick(this.scrollToBottom);
      }
    });

    hubConnection.on("GetConnectionId", (connectionId) => {
      this.$store.commit("SET_SIGNALR_CONNECTION", connectionId);
    });

    hubConnection.on("EndReceiveMessage", () => {
      this.botIsGenerateAnswer = false;
      _.delay(() => {
        this.$refs.textbox.focus();
      }, 100);
    });

    await hubConnection.start();
    await hubConnection.invoke("GetConnectionId");
  },
};
</script>

<style scoped lang="scss">
$font-stack: "ProximaNova", "Montserrat", "Avenir Next", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";

#keyreply-chat-window {
  position: fixed;

  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
  // overflow:visible;

  background-color: white;
  // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(71, 69, 123, 0.24), 0 2px 4px rgba(71, 69, 123, 0.12);
  text-rendering: optimizeLegibility;

  // border: 1px solid #eee;

  /* TODO: change this to the highest */
  z-index: 2000;
  border-radius: 10px;

  &.tall,
  &.tall #keyreply-panel-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .keyreply-livechat-banner {
    position: absolute;
    top: 54px;
    border-radius: 0 0 4px 4px;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    background-color: #f0f9eb;
    color: #67c23a;
    font-size: 13px;
    font-weight: bold;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 5px;
    z-index: 2002;
  }

  .keyreply-livechat-banner-large {
    position: absolute;
    top: 54px;
    border-radius: 0 0 4px 4px;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    background-color: #f0f9eb;
    color: #67c23a;
    font-size: 13px;
    font-weight: bold;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 5px;
  }

  .keyreply-disconnected-banner {
    position: absolute;
    top: 54px;
    border-radius: 0 0 4px 4px;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    background-color: #bfbfbf;
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 3px;
  }

  .keyreply-notification-banner {
    height: auto;
    text-align: center;
    font-size: 12px;
    top: 54px;
    padding: 5px;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    background-color: #bfbfbf;
    color: #000000;
    font-weight: bold;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 5px;
  }

  #keyreply-notification-banner-text {
    p {
      display: inline !important;
    }
  }

  .keyreply-maintenance-banner {
    height: auto;
    text-align: center;
    font-size: 12px;
    top: 44px;
    padding: 5px;
    position: absolute;
    border-radius: 0 0 4px 4px;
    width: 100%;
    box-sizing: border-box;
    background-color: #bfbfbf;
    color: #000000;
    font-weight: bold;
    // left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 5px;
    margin-top: 16px;
  }

  #keyreply-maintenance-banner-text {
    p {
      display: inline !important;
    }
  }

  .keyreply-disconnected-banner-text {
    position: absolute;
    top: 16px;
    text-align: center;
    overflow: hidden;
    z-index: 999999999;
  }

  .keyreply-livechat-banner-text {
    position: absolute;
    top: 16px;
    text-align: center;
    overflow: hidden;
  }

  .keyreply-livechat-end-button {
    margin: 8px;
    color: #409eff;
    background: #ecf5ff;
    border-color: #b3d8ff;
    cursor: pointer;
  }

  #keyreply-panel-header {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px !important;
    z-index: 1;
    width: 100%;
    padding: 13px 10px 12px;
    zoom: 1;
    cursor: pointer;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06), 0 2px 0 0 rgba(0, 0, 0, 0.01);
    border-radius: 10px 10px 0px 0px;
    text-align: left;

    .keyreply-chat-title {
      display: inline-block;
      position: absolute;
      margin-top: 10px;
      line-height: 100%;
      font-size: 20px;
      padding-left: 5px;

      &.keyreply-chat-logo-large {
        margin-left: 118px;
      }
    }

    .keyreply-header-button {
      cursor: pointer;
      position: absolute;
      top: 20px;
    }

    .keyreply-chat-logo {
      display: inline-block;
      height: 100%;
      max-height: 50px;
    }

    .keyreply-chat-logo-large {
      top: -55px;
      z-index: 0;
      position: absolute;
      height: 100px;
      width: 100px;
    }

    .keyreply-chat-title-large {
      position: absolute;
      margin-left: 124px;
      font-size: 27px;
    }
  }

  #keyreply-panel-body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    position: absolute;
    bottom: 40px;
    top: 60px;
    width: 100%;
    background-position: center;
    padding: 16px 0;
    z-index: 0;

    .keyreply-subtle-link {
      text-align: center;
      color: rgba(0, 0, 0, 0.7);
      margin: 4px auto;
      font-size: 12px;
      cursor: pointer;
    }
  }

  #keyreply-panel-body-form {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    position: absolute;
    bottom: 40px;
    top: 60px;
    width: 100%;
    background-position: center;
    padding: 0;
    z-index: 0;
  }

  #keyreply-panel-frame {
    overflow-x: hidden;
    position: absolute;
    top: 60px;
    bottom: 0px;
    width: 100%;
    padding: 0;
    z-index: 2;
  }

  #keyreply-panel-footer {
    display: flex;
    position: absolute;
    top: auto;
    padding: 0;
    bottom: 0px;
    left: 0px !important;
    right: 0px !important;
    z-index: 900;
    background-color: #fff;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.04), 0px -2px 0px 0px rgba(0, 0, 0, 0.01);
    z-index: 1;

    .keyreply-footer-button {
      min-height: 30px;
      display: inline-block;
      padding: 10px;
      text-align: center;
      z-index: 2;
      color: grey;
      cursor: pointer;

      i {
        height: 30px;
        width: 30px;
        font-size: 24px;
      }
    }

    .keyreply-footer-button.keyreply-send-button {
      align-items: center;
      display: flex;
      flex: 0 0 36px;
      transition: transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);

      &.visible {
        color: #e4392b;
      }
      &.disabled {
        color: grey;
        pointer-events: none;
      }
    }

    .keyreply-autocomplete-popper {
      z-index: 1000 !important;
      font-family: $font-stack;
      font-weight: 400;
      position: absolute;
      bottom: 46px;
      background: white;
      opacity: 0.9;
      width: 100%;
    }

    .keyreply-suggestion {
      opacity: 1;
      color: #212b35;
      white-space: normal;
      word-break: break-word;
      cursor: pointer;
      opacity: 1;
      padding: 8px;

      transition: background-color 0.35s ease-in;

      &.keyreply-suggestion-selected {
        background-color: rgb(220, 220, 220);
      }
    }
  }

  #keyreply-scroll-button {
    position: absolute;
    right: 25px;
    bottom: 60px;
    width: 55px;
    height: 50px;
    min-width: 0;

    .circle-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 0;
    }

    .circle-button:hover ~ .info-button {
      visibility: visible;
      opacity: 1;
    }
    .circle-button:hover {
      opacity: 0;
      visibility: hidden;
    }

    .info-button {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 4px;
      display: block;
      white-space: normal;
      text-align: center;
      max-width: 55px;
      width: auto;
      font-size: 12px;
      visibility: hidden;
      opacity: 0;
      min-width: 0;

      p {
        margin: 0;
      }

      &:hover {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    right: 0 !important;
    bottom: 0 !important;
  }

  @media only screen and (max-height: 650px) {
    height: 100% !important;
  }
}

@media only screen and (max-height: 660px) {
  #keyreply-panel-header .keyreply-chat-logo-large {
    display: inline-block;
    height: 44px !important;
    width: 44px !important;
    top: -5px !important;
  }
}

@media only screen and (max-width: 768px) {
  #keyreply-chat-window {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }

  #keyreply-panel-header {
    .keyreply-chat-logo-large {
      display: inline-block;
      height: 44px !important;
      width: 44px !important;
      top: -5px !important;
    }

    .keyreply-chat-title-large {
      margin-left: 55px !important;
      font-size: 20px !important;
    }
  }
}

@media only screen and (min-width: 1024px) {
  #keyreply-chat-window {
    width: 410px;
    height: 640px;
    bottom: 10px;

    &.wide {
      width: 550px;
    }

    &.full {
      top: 0 !important;
      left: 0 !important;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    transition: height 0.3s, right 0.3s, bottom 0.3s;
  }

  #keyreply-chat-window.iossdk {
    margin-right: 0;
  }

  #keyreply-container.keyreply-left #keyreply-chat-window {
    right: unset;
    bottom: 10px;
    left: 20px;
  }
}

@media only print {
  #keyreply-chat-window {
    display: none !important;
  }
}

.floating-mobile-menu-icon:hover {
  fill: #fff;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2001;
}

.lightbox .lightbox-close-button {
  position: absolute;
  top: 5px;
  right: 20px;
  color: white;
  font-size: 50px;
  cursor: pointer;
}

.full-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.image-lightbox {
  z-index: 1;
}
</style>
<style lang="scss">
$font-stack: "ProximaNova", "Montserrat", "Avenir Next", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";

.el-notification__small {
  width: 310px !important;
  right: 6px !important;
}

.el-tabs__header {
  margin-bottom: 0 !important;
}

#keyreply-chat-window.el-tabs__item.is-top {
  color: white;
}

.keyreply-message-input textarea {
  min-height: 38px !important;
  border: none !important;
  resize: none;
  border-radius: 0px;
  padding: 5px 5px;
  box-sizing: border-box;
}

.keyreply-message-input textarea:focus {
  border: none !important;
}

textarea {
  font: inherit;
}

.el-textarea__inner {
  font-size: 16px !important;
}

.keyreply-message-input {
  flex: 1;
  width: 100%;
  -webkit-writing-mode: horizontal-tb !important;
  resize: none;
  overflow: hidden !important;
  clear: both;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  -webkit-rtl-ordering: logical;
  flex-direction: column;
  outline-offset: -2px;

  border: 1px solid #fff;
  // padding: 0;
  font: inherit !important;
  border-radius: 4px;

  background-color: #fff;
  color: #212121;
  outline: 0;

  -webkit-appearance: none;
  overflow: hidden;
  overflow-y: scroll;

  // padding-left: 47px;
  width: 314px;
  height: auto;
  // padding-right: 49px;
  box-sizing: border-box;

  // border-top: 13px solid #fff;
  // border: 1px solid #77bbff;
  transition: all 0.35s ease-out;

  max-height: 200px !important;
  padding: 4px 0px;
  margin-left: 10px;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #b1bbc7;
  font-size: 14px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b1bbc7;
  font-size: 14px;
}

::placeholder {
  color: #b1bbc7;
  font-size: 14px;
}

.keyreply-email-dialog {
  position: absolute !important;
  left: 0px !important;
  top: 0px !important;
}
.keyreply-video {
  padding: 0px !important;
  overflow: hidden !important;
  bottom: 0px !important;
}
</style>

<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.2s;
}
.bounce-leave-active {
  animation: bounce-in 0.2s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>

<style lang="scss">
.ui-beta {
  #keyreply-chat-window {
    box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.2);
    border-radius: 16px;

    &.tall #keyreply-panel-footer {
      border-radius: 0;
    }

    &.tall #keyreply-header-menu {
      border-radius: 0;
    }

    .keyreply-disconnected-banner {
      top: 60px;
      height: 32px;
      background-color: #ffcccc;
      color: #cc0000;
      font-size: 12px;
    }

    .keyreply-typing-banner {
      position: absolute;
      top: 60px;
      border-radius: 0 0 4px 4px;
      width: 100%;
      height: 32px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: bold;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding-left: 3px;
      background: #dce2f5;
      color: #a6b5e6;
    }

    #keyreply-header-menu {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #0000008c;
      border-radius: 16px;
      z-index: 99;
      .keyreply-header-menu-container {
        position: absolute;
        right: 16px;
        left: 16px;
        top: 96px;
        padding: 24px 16px 16px 16px;
        background: #fff;
        border-radius: 16px;
      }

      .keyreply-header-menu-section {
        background: #dce2f5;
        border-radius: 8px;
        padding: 16px;

        &:nth-child(2) {
          margin: 16px 0;
        }

        .keyreply-header-menu-section-tittle {
          font-size: 16px;
          font-weight: 900;
          color: #4e6cce;
        }

        .keyreply-header-menu-button-group {
          display: flex;
          margin-top: 16px;

          .keyreply-header-button-item {
            display: flex;
            align-items: center;
            width: 50%;
            cursor: pointer;

            .keyreply-header-button {
              height: 32px;
              width: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              color: #fff;
              background: #4e6cce;
              border-radius: 200px;
              z-index: 2;

              i {
                font-size: 16px;
              }
            }

            span {
              font-size: 16px;
            }
          }
        }
      }

      .keyreply-header-powered {
        margin-top: 8px;
        text-align: center;
        font-size: 14px;
        color: #999999;
      }
    }

    #keyreply-panel-header {
      cursor: grab;
      border-radius: 16px 16px 0px 0px;

      .keyreply-chat-title {
        font-size: 16px;
      }

      .keyreply-header-button {
        top: 50%;
        transform: translateY(-45%);
        height: 32px;
      }

      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        cursor: default !important;
      }
    }

    #keyreply-panel-body {
      bottom: 60px;

      .keyreply-powered {
        font-size: 14px;
        color: #999999;
      }
    }

    #keyreply-panel-footer {
      align-items: center;
      padding: 16px;
      background-color: #eee;
      border-radius: 0px 0px 16px 16px;
      min-height: 46px;

      .keyreply-footer-button {
        min-height: 32px;
        min-width: 32px;
        display: flex;
        display: block !important;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 200px;
        z-index: 2;
        padding: 2px;

        i {
          font-size: 24px;
          width: auto;
          height: auto;
        }
      }

      .keyreply-footer-button.keyreply-send-button {
        display: none;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
    }

    @media only screen and (max-height: 650px) {
      height: 100% !important;
    }

    .keyreply-message-input textarea {
      padding: 7px 16px 6px 16px;
      font-size: 16px;
    }

    .keyreply-message-input textarea::placeholder {
      font-size: 16px;
      color: #ccc;
    }

    .el-textarea__inner {
      font-size: 16px !important;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      color: #b1bbc7;
      font-size: 16px;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #b1bbc7;
      font-size: 16px;
    }

    ::placeholder {
      color: #b1bbc7;
      font-size: 16px;
    }

    .keyreply-email-dialog {
      position: absolute !important;
      top: 0px !important;
    }

    .keyreply-message-input {
      order: -1;
      border-radius: 50px;
      margin-left: 0;
      min-height: 32px;
    }
  }

  #keyreply-scroll-button {
    bottom: 85px !important;
  }
}
</style>

<style lang="scss" scoped>
[dir="ltr"] #keyreply-chat-window {
  .keyreply-livechat-banner {
    left: 0;
    padding-left: 5px;
  }

  .keyreply-livechat-banner-large {
    left: 0;
    padding-left: 5px;
  }

  .keyreply-disconnected-banner {
    left: 0;
    padding-left: 3px;
  }

  .keyreply-notification-banner {
    left: 0;
    padding-left: 5px;
  }

  .keyreply-maintenance-banner {
    left: 0;
    padding-left: 5px;
  }

  #keyreply-header-menu {
    .keyreply-header-menu-section {
      .keyreply-header-menu-button-group {
        .keyreply-header-button-item {
          .keyreply-header-button {
            margin-right: 16px;
          }
        }
      }
    }
  }

  #keyreply-panel-header {
    text-align: left;
    background-color: green;

    .keyreply-chat-title {
      padding-left: 5px;

      &.keyreply-chat-logo-large {
        margin-left: 118px;
      }
    }

    .keyreply-header-button {
      right: 17px;
    }

    .keyreply-chat-title-large {
      margin-left: 124px;
    }
  }

  #keyreply-panel-body {
    .keyreply-powered {
      text-align: right;
      margin-right: 16px;
    }
    .keyreply-powered.mobile-mode {
      text-align: center;
    }
  }

  #keyreply-panel-footer {
    .keyreply-footer-button {
      margin-left: 16px;
    }
  }
}

[dir="rtl"] #keyreply-chat-window {
  .keyreply-livechat-banner {
    right: 0;
    padding-right: 5px;
  }

  .keyreply-livechat-banner-large {
    right: 0;
    padding-right: 5px;
  }

  .keyreply-disconnected-banner {
    right: 0;
    padding-right: 3px;
  }

  .keyreply-notification-banner {
    right: 0;
    padding-right: 5px;
  }

  .keyreply-maintenance-banner {
    right: 0;
    padding-right: 5px;
  }

  #keyreply-header-menu {
    .keyreply-header-menu-section {
      .keyreply-header-menu-button-group {
        .keyreply-header-button-item {
          .keyreply-header-button {
            margin-left: 16px;
          }
        }
      }
    }
  }

  #keyreply-panel-header {
    text-align: right;

    .keyreply-chat-title {
      padding-right: 5px;

      &.keyreply-chat-logo-large {
        margin-right: 118px;
      }
    }

    .keyreply-header-button {
      left: 17px;
    }

    .keyreply-chat-title-large {
      margin-right: 124px;
    }
  }

  #keyreply-panel-body {
    .keyreply-powered {
      text-align: left;
      margin-left: 16px;
    }
    .keyreply-powered.mobile-mode {
      text-align: center;
    }
  }

  #keyreply-panel-footer {
    .keyreply-footer-button {
      margin-right: 16px;
    }
  }
}

@media only screen and (max-height: 660px) {
  #keyreply-panel-header {
    cursor: default !important;
  }
}

@media only screen and (max-width: 768px) {
  #keyreply-panel-header {
    cursor: default !important;
  }

  [dir="ltr"] #keyreply-panel-header {
    .keyreply-chat-title-large {
      margin-left: 55px !important;
    }
  }

  [dir="rtl"] #keyreply-panel-header {
    .keyreply-chat-title-large {
      margin-right: 55px !important;
    }
  }
}

@media only screen and (min-width: 1024px) {
  [dir="ltr"] #keyreply-chat-window {
    transition: height 0.3s, right 0.3s, bottom 0.3s;
  }

  [dir="rtl"] #keyreply-chat-window {
    transition: height 0.3s, left 0.3s, bottom 0.3s;
  }

  [dir="ltr"] #keyreply-chat-window.iossdk {
    margin-right: 0;
  }

  [dir="rtl"] #keyreply-chat-window.iossdk {
    margin-left: 0;
  }

  #keyreply-container.keyreply-left #keyreply-chat-window {
    bottom: 10px;
  }

  [dir="ltr"] #keyreply-container.keyreply-left #keyreply-chat-window {
    right: unset;
    left: 20px;
  }

  [dir="rtl"] #keyreply-container.keyreply-left #keyreply-chat-window {
    left: unset;
    right: 20px;
  }
}

[dir="ltr"] .lightbox {
  left: 0;
}

[dir="rtl"] .lightbox {
  right: 0;
}

[dir="ltr"] .el-notification__small {
  right: 6px !important;
}

[dir="rtl"] .el-notification__small {
  left: 6px !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss" scoped>
$dot-width: 5px;
$dot-color: #999999;
$speed: 1.5s;

.typing {
  position: relative;
  height: fit-content;
  margin: 10px 5px;

  span {
    content: "";
    animation: blink $speed infinite;
    animation-fill-mode: both;
    height: $dot-width;
    width: $dot-width;
    background: $dot-color;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;

    &:nth-child(2) {
      animation-delay: 0.2s;
      margin-left: $dot-width * 1.5;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
      margin-left: $dot-width * 3;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.keyreply-maintenance-banner-close {
  position: absolute;
  top: -10px;
  right: 5px;
  cursor: pointer;
  color: red !important;
  font-size: 16px;
}
</style>
